import React from 'react';
import { Modal, Button } from "@mui/material";

const DeleteConfirmationModal = ({ open, onClose, onConfirm }) => (
  <Modal open={open} onClose={onClose}>
    <div style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      width: '400px',
    }}>
      <h3>Confirm Delete</h3>
      <p>Are you sure you want to delete this ?</p>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '20px' }}>
        <Button onClick={onClose} variant="outlined">Cancel</Button>
        <Button onClick={onConfirm} variant="contained" color="error">Delete</Button>
      </div>
    </div>
  </Modal>
);

export default DeleteConfirmationModal;