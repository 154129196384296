import { apiCall } from "../api";


export const gearBoxApi={
    addGearBoxInfo:(gearBoxData)=>apiCall('post','/gearbox/addGearboxInfo',gearBoxData),
    addMonitoringSession:(monitoringData)=>apiCall('post','/gearbox/addMonitoringSession',monitoringData),
    getMonitoringData:()=>apiCall('get','/gearbox/gearboxMonitoring'),
    getGearBoxReport:()=>apiCall('get','/gearbox/gearboxReportDetails'),
    getCustomerDetails:()=>apiCall('get','/gearbox/customerReportDetails')

}