import React, { useState, useEffect, useRef } from "react";
import {
  Paper,
  Box,
  Typography,
  Stack,
  Grid,
  Switch,
  Button,
} from "@mui/material";
import { Timer, Monitor } from "@mui/icons-material";
import BearingDataTable from "./BearingTable";
import { gearBoxApi } from "../../api/PTPL API/gearBoxApi";
import html2canvas from "html2canvas";
import cLogo from "../../assets/images/PTPL_LOGO.jpeg-removebg-preview.png";
import jsPDF from "jspdf";
const getBackgroundColor = (colorIndicator) => {
  const colors = {
    RED: "rgba(255, 0, 0,0.4)",
    GREEN: "rgba(0, 255, 0,0.4 )",
    YELLOW: "rgba(255, 255, 0,0.4 )",
  };
  return colors[colorIndicator] || "white";
};
const radiusColor = (colorIndicator) => {
  const colors = {
    RED: "rgba(255, 0, 0)",
    GREEN: "rgba(0, 255, 0, )",
    YELLOW: "rgba(255, 255, 0, )",
  };
  return colors[colorIndicator] || "white";
};
const printStyles = `
  @media print {
    @page {
      size: A4;
      margin: 20mm;
    }
    
    nav, header, footer {
      display: none !important;
    }
    
    body * {
      visibility: hidden;
    }
    
    #printableReport, #printableReport * {
      visibility: visible;
    }
    
    #printableReport {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
    
    button {
      display: none !important;
    }
  }
`;
const PTPLTestingReport = () => {
  const [isRunning, setIsRunning] = useState(false);
  const [bodyTemp, setBodyTemp] = useState("");
  const [systemStatus, setSystemStatus] = useState("ok");
  const [monitoringData, setMonitoringData] = useState(null);
  const [sensorData, setSensorData] = useState([]);
  const [gearboxInfo, setGearboxInfo] = useState(null);
  const [rhbNoiseTemperature, setRhbNoiseTemperature] = useState(false);
  const reportRef = useRef(null);

  const handleRhbNoiseToggle = () => {
    setRhbNoiseTemperature(!rhbNoiseTemperature);
  };

  useEffect(() => {
    fetchTestingData();
  }, []);

  const calculateTotalTime = (startTime, stopTime) => {
    if (!startTime || !stopTime) return "00:00:00";

    const start = new Date(startTime.replace(/-/g, "/"));
    const stop = new Date(stopTime.replace(/-/g, "/"));
    const diffInSeconds = Math.floor((stop - start) / 1000);

    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  const fetchTestingData = async () => {
    try {
      const response = await gearBoxApi.getGearBoxReport();
      const { monitoringSession, sensorData, gearboxInfo } =
        response.data[0].gearboxReportDetails;

      setMonitoringData(monitoringSession);
      setSensorData(sensorData);
      setGearboxInfo(gearboxInfo);
    } catch (error) {
      console.error("Error getting gearbox report:", error);
    }
  };
  const handleDownloadAndPrint = async () => {
    try {
      const element = reportRef.current;

      const wrapper = document.createElement("div");
      wrapper.style.padding = "20px";

      const clone = element.cloneNode(true);
      wrapper.appendChild(clone);

      const enterButton = clone.querySelector("#enter-button");
      if (enterButton) {
        enterButton.style.display = "none";
      }

      document.body.appendChild(wrapper);

      const canvas = await html2canvas(wrapper, {
        scale: 2,
        logging: false,
        useCORS: true,
        backgroundColor: null,
        removeContainer: true,
      });

      document.body.removeChild(wrapper);

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a4",
      });

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const ratio = Math.min(
        pdfWidth / imgProps.width,
        pdfHeight / imgProps.height
      );
      const width = imgProps.width * ratio;
      const height = imgProps.height * ratio;

      const x = (pdfWidth - width) / 2;
      const y = (pdfHeight - height) / 2;

      pdf.addImage(imgData, "PNG", x, y, width, height);
      pdf.save(`PTPL_Report_${gearboxInfo?.gearboxSerialNo || "unknown"}.pdf`);

      const printWindow = window.open("", "", "width=1200,height=900");
      printWindow.document.write(`
        <html>
          <head>
            <style>
              @media print {
                @page { 
                  size: landscape;
                  margin: 0;
                }
                html, body {
                  margin: 0;
                  padding: 0;
                  height: 100%;
                }
                body {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  min-height: 100vh;
                }
                .report-content {
                  display: block;
                  page-break-inside: avoid;
                  page-break-before: auto;
                  page-break-after: auto;
                }
                .report-image {
                  max-width: 100%;
                  height: auto;
                  display: block;
                  margin: 0 auto;
                }
              }
              /* Hide scrollbars during print */
              @media screen {
                body {
                  overflow: hidden;
                }
              }
            </style>
          </head>
          <body>
            <div class="report-content">
              <img src="${imgData}" class="report-image" alt="Report" />
            </div>
          </body>
        </html>
      `);

      printWindow.document.close();
      printWindow.focus();

      setTimeout(() => {
        printWindow.print();
        printWindow.close();
      }, 1500);
    } catch (error) {
      console.error("Error generating PDF/Print:", error);
    }
  };
  const bearingData =
    sensorData?.map((sensor) => ({
      id: parseInt(sensor.deviceNo),
      label: sensor.bearingPosition,
      value: `Vib X:${sensor.velX}, Y:${sensor.velY}, Z:${sensor.velZ}, ACC:${sensor.acceleration}, Temp:${sensor.tempC}°C, Noise:${sensor.noiseDb}dB`,
    })) || [];
  return (
    <Paper
      elevation={3}
      ref={reportRef}
      sx={{
        p: 3,
        m: 2,
        borderRadius: 2,
        // border: `20px solid ${radiusColor(monitoringData?.colorIndicator)}`,
        border: `20px solid ${monitoringData?.colorIndicator}`,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          mb: 1,
          mt: 1,
        }}
      >
        <img
          src={cLogo}
          alt="PTPL Logo"
          style={{
            height: "50px",
            objectFit: "contain",
          }}
        />
      </Box>
      <Box
        sx={{
          p: 1,
          mb: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: "#fff",
          borderRadius: 1,
          boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
        }}
      >
        <Typography
          sx={{
            bgcolor: "#f1f8ff",
            p: 1,
            borderRadius: 1,
            fontSize: "24px",
            fontWeight: 500,
          }}
        >
          Serial No: {gearboxInfo?.gearboxSerialNo || "N/A"}
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{
            bgcolor: "#f1f8ff",
            p: 1,
            borderRadius: 1,
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Total Processed Time:
          </Typography>
          <Timer sx={{ color: "#1976d2", marginLeft: "2px !important" }} />
          <Typography variant="h5" sx={{ fontWeight: 600, color: "#2c3e50" }}>
            {calculateTotalTime(
              monitoringData?.startTime,
              monitoringData?.stopTime
            )}
          </Typography>
        </Stack>
      </Box>

      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center", mt: 1 }}
        >
          <BearingDataTable bearingData={bearingData} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  p: 1,
                  bgcolor: "#fff",
                  borderRadius: 1,
                  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1, p: 1 }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#2b07c3", fontWeight: 600, fontSize: "22px" }}
                  >
                    Oil Temp:
                  </Typography>
                  <Typography sx={{ fontWeight: 600, fontSize: "22px" }}>
                    {monitoringData?.oilTemp || 0} °C
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  p: 1,
                  bgcolor: "#fff",
                  borderRadius: 1,
                  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1, p: 1 }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#2b07c3", fontWeight: 600, fontSize: "22px" }}
                  >
                    Ambient Temp:
                  </Typography>
                  <Typography sx={{ fontWeight: 600, fontSize: "22px" }}>
                    {monitoringData?.ambientTemp || 0} °C
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  p: 1,
                  bgcolor: "#fff",
                  borderRadius: 1,
                  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1, p: 1 }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#2b07c3", fontWeight: 600, fontSize: "22px" }}
                  >
                    System Temperature:
                  </Typography>
                  <Typography sx={{ fontWeight: 600, fontSize: "22px" }}>
                    {" "}
                    {monitoringData?.systemTemp || 0} °C
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  p: 1,
                  bgcolor: "#fff",
                  borderRadius: 1,
                  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1, p: 1 }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#2b07c3", fontWeight: 600, fontSize: "22px" }}
                  >
                    Input RPM:
                  </Typography>
                  <Typography sx={{ fontWeight: 600, fontSize: "22px" }}>
                    {monitoringData?.inputRpm || 0}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  p: 1,
                  bgcolor: "#fff",
                  borderRadius: 1,
                  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1, p: 1 }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#2b07c3", fontWeight: 600, fontSize: "22px" }}
                  >
                    Output RPM:
                  </Typography>
                  <Typography sx={{ fontWeight: 600, fontSize: "22px" }}>
                    {monitoringData?.outputRpm || 0}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  p: 1,
                  bgcolor: "#fff",
                  borderRadius: 1,
                  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1, p: 1 }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#2b07c3", fontWeight: 600, fontSize: "22px" }}
                  >
                    System Noise :
                  </Typography>
                  <Typography sx={{ fontWeight: 600, fontSize: "22px" }}>
                    {monitoringData?.overallNoise || 0}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              p: 2,
              bgcolor: "#fff",
              borderRadius: 1,
              boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent:
                  monitoringData?.colorIndicator === "RED"
                    ? "space-between"
                    : "center",
              }}
            >
              {monitoringData?.colorIndicator === "RED" && (
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 600, fontSize: "22px", color: "black" }}
                >
                  System Alert:{" "}
                  <span style={{ color: `${monitoringData?.colorIndicator}` }}>
                    {monitoringData?.status || "none"}
                  </span>
                  <br />
                  Remarks: {monitoringData?.remarks || "none"}
                  <br />
                  Reason: {monitoringData?.reason || "none"}
                </Typography>
              )}

              <Box
                sx={{
                  // width: "100px",
                  // height: "100px",
                  fontSize: "85px",
                  fontWeight: "900",

                  color:
                    monitoringData?.colorIndicator === "RED"
                      ? "rgba(255, 0, 0, 0.8)"
                      : "Green",
                  // borderRadius: "50%",
                }}
              >
                {monitoringData?.colorIndicator === "RED"
                  ? "Rejected"
                  : "Accepted"}
              </Box>
            </Box>
          </Box>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              id="enter-button"
              onClick={handleDownloadAndPrint}
              sx={{
                height: "56px",
                textTransform: "none",
                fontSize: "1.1rem",
                fontWeight: 600,
                color: "white",
                boxShadow: "0 4px 6px rgba(25, 118, 210, 0.25)",
                "&:hover": {
                  boxShadow: "0 6px 8px rgba(25, 118, 210, 0.35)",
                },
              }}
            >
              ENTER
            </Button>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PTPLTestingReport;
