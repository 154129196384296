import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  styled,
  tableCellClasses,
  TablePagination,
  Skeleton,
  Modal,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";


import { customerApi } from "../../api/PTPL API/customerApi";
import DeleteConfirmationModal from "./Modals/DeleteConfirmationModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomerMaster() {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success"
  });

  const [formData, setFormData] = useState({
    companyName: "",
  });

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const response = await customerApi.getCustomer();
      setCustomers(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching customers:", error);
      showSnackbar("Error loading customers", "error");
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddSubmit = async () => {
    try {
      await customerApi.addCustomer({ companyName: formData.companyName });
      setAddOpen(false);
      fetchCustomers();
      showSnackbar("Customer added successfully", "success");
      setFormData({ companyName: "" });
    } catch (error) {
      console.error("Error adding customer:", error);
      showSnackbar("Error adding customer", "error");
    }
  };

  const handleEditClick = (customer) => {
    setSelectedCustomer(customer);
    setFormData({ companyName: customer.companyName });
    setEditOpen(true);
  };

  const handleEditSubmit = async () => {
    try {
      await customerApi.updateCustomer(selectedCustomer._id, { 
        companyName: formData.companyName 
      });
      setEditOpen(false);
      fetchCustomers();
      showSnackbar("Customer updated successfully", "success");
    } catch (error) {
      console.error("Error updating customer:", error);
      showSnackbar("Error updating customer", "error");
    }
  };

  const handleDeleteClick = (customer) => {
    setSelectedCustomer(customer);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await customerApi.deleteCustomer(selectedCustomer._id);
      setDeleteModalOpen(false);
      fetchCustomers();
      showSnackbar("Customer deleted successfully", "success");
    } catch (error) {
      console.error("Error deleting customer:", error);
      showSnackbar("Error deleting customer", "error");
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{ padding: "0px 20px" }}>
      <div style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: "5px",
        paddingBottom: "5px",
      }}>
        <h2>Customer Master</h2>
        <Button
          onClick={() => setAddOpen(true)}
          style={{
            fontWeight: "500",
            borderRadius: "4px",
            color: "gray",
            border: "2px solid gray",
            padding: "5px",
            marginTop: "5px",
          }}
        >
          Add New &nbsp;
          <FontAwesomeIcon style={{ fontSize: "18px", color: "gray" }} icon={faPlus} />
        </Button>
      </div>

      <Box>
        <Table size="small" style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Company Name</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              Array.from(Array(5).keys()).map((index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell><Skeleton animation="wave" /></StyledTableCell>
                  <StyledTableCell><Skeleton animation="wave" /></StyledTableCell>
                  <StyledTableCell><Skeleton animation="wave" /></StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              customers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((customer, index) => (
                  <StyledTableRow key={customer._id}>
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>{customer.companyName}</StyledTableCell>
                    <StyledTableCell style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}>
                      <IconButton onClick={() => handleEditClick(customer)}>
                        <EditIcon />
                      </IconButton>
                      <div className="divider" style={{
                        height: "20px",
                        width: "2px",
                        backgroundColor: "#0003",
                      }}></div>
                      <IconButton 
                        style={{ color: "#FF3131" }}
                        onClick={() => handleDeleteClick(customer)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
            )}
          </TableBody>
        </Table>
        
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={customers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        {/* Add Modal */}
        <Modal open={addOpen} onClose={() => setAddOpen(false)}>
          <div style={{
            borderRadius: "10px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            minWidth: "500px",
          }}>
            <button
              onClick={() => setAddOpen(false)}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "30px",
              }}
            >
              &times;
            </button>
            <h2>Add New Customer</h2>
            <hr />
            <br />
            <TextField
              label="Company Name"
              name="companyName"
              value={formData.companyName}
              onChange={handleInputChange}
              style={{
                width: "100%",
                marginBottom: "10px",
              }}
            />
            <Button
              onClick={handleAddSubmit}
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
            >
              Add
            </Button>
          </div>
        </Modal>

        {/* Edit Modal */}
        <Modal open={editOpen} onClose={() => setEditOpen(false)}>
          <div style={{
            borderRadius: "10px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            minWidth: "500px",
          }}>
            <button
              onClick={() => setEditOpen(false)}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "30px",
              }}
            >
              &times;
            </button>
            <h2>Update Customer</h2>
            <hr />
            <br />
            <TextField
              label="Company Name"
              name="companyName"
              value={formData.companyName}
              onChange={handleInputChange}
              style={{
                width: "100%",
                marginBottom: "10px",
              }}
            />
            <Button
              onClick={handleEditSubmit}
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
            >
              Update
            </Button>
          </div>
        </Modal>

        {/* Delete Confirmation Modal */}
        <DeleteConfirmationModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleConfirmDelete}
        />

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        >
          <MuiAlert
            onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </MuiAlert>
        </Snackbar>
      </Box>
    </div>
  );
}