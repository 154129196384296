import React from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TablePagination,
  Skeleton,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from './Tablestyle';


const CustomTable = ({ 
  columns, 
  data, 
  loading, 
  page, 
  rowsPerPage, 
  onPageChange, 
  onRowsPerPageChange,
  renderActions 
}) => {
  return (
    <>
      <Table size="small" style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell key={column.id}>{column.label}</StyledTableCell>
            ))}
            <StyledTableCell>Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            Array.from(Array(5).keys()).map((index) => (
              <StyledTableRow key={index}>
                {Array.from(Array(columns.length).keys()).map((cellIndex) => (
                  <StyledTableCell key={cellIndex}>
                    <Skeleton animation="wave" />
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))
          ) : (
            data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <StyledTableRow key={index}>
                  {columns.map((column) => (
                    <StyledTableCell key={column.id}>
                      {row[column.id]}
                    </StyledTableCell>
                  ))}
                  {renderActions && (
                    <StyledTableCell>
                      {renderActions(row)}
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </>
  );
};

export default CustomTable;