import axios from "axios";
import { baseUrl } from "./baseUrl";


const api = axios.create({
  baseURL: baseUrl,
});

api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

// api.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response && error.response.status === 401) {
//       localStorage.removeItem("token");

//       window.location.href = "/#/login";
//     }
//     return Promise.reject(error);
//   }
// );

export const apiCall = async (method, endpoint, data = null) => {
  try {
    const response = await api[method](endpoint, data);
    return response.data;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};
