import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Tangent } from "lucide-react";
import { gearBoxApi } from "../../api/PTPL API/gearBoxApi";
import jsPDF from "jspdf";
import "jspdf-autotable";
import cLogo from "../../assets/images/PTPL_LOGO.jpeg-removebg-preview.png";
import { useNavigate } from "react-router-dom";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
  whiteSpace: "nowrap",
  background: "white",
  color: "black",
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: 400,
  overflow: "auto",
  "& .MuiTableHead-root": {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: "white",
  },
}));
const replaceNullsWithDash = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => replaceNullsWithDash(item));
  } else if (typeof data === "object" && data !== null) {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        replaceNullsWithDash(value),
      ])
    );
  } else {
    return data == null || data === "" ? "-" : data;
  }
};

const SoftwareReportTable = () => {
  const navigate = useNavigate();
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [reportData, setReportData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a3",
    });

    const imgData = cLogo;
    const pageWidth = doc.internal.pageSize.getWidth();

    const logoWidth = 30; // slightly smaller width
    const logoHeight = 12; // slightly smaller height
    const logoX = 15; // fixed left position
    const logoY = 5;  // top position

    try {
      doc.addImage(imgData, "PNG", logoX, logoY, logoWidth, logoHeight);
    } catch (error) {
      console.error("Error adding logo:", error);
    }
    doc.setFontSize(16);
    const title = "Testing Report";
    const titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const titleX = (pageWidth - titleWidth) / 2;
    doc.text(title, titleX, 15);
    const contentStartY = Math.max(logoY + logoHeight, 20) + 5; // Ensure content starts below both logo and title
    doc.setFontSize(12);
    const basicInfo = [
      [
        `PTPL Order No: ${reportData?.gearboxInfo?.ptplOrderNo ?? "-"}`,
        `Date: ${formatDay(reportData?.monitoringSession?.startTime)}`,
        `Shift Gen: ${reportData?.monitoringSession?.shift ?? "-"}`,
      ],
      [
        `Customer: ${reportData?.gearboxInfo?.customerName || "-"}`,
        `Gearbox Box Sr. No: ${
          reportData?.gearboxInfo?.gearboxSerialNo ?? "-"
        }`,
      ],
      [
        `Unit details: ${reportData?.gearboxInfo?.unitDetail || "-"}`,
        `Ratio: -`,
        `Req I/P speed: ${reportData?.monitoringSession?.inputRpm || "-"} RPM`,
        `FLS/Pump Details-Make: ${
          reportData?.gearboxInfo?.flsPumpDetails ?? "NA"
        }`,
        `Capacity: ${reportData?.gearboxInfo?.flsCapacity ?? "NA"}`,
        `Drawing No: ${reportData?.gearboxInfo?.drawingNo ?? "NA"}`,
      ],
      [
        `Testing started at: ${formatTime(
          reportData?.monitoringSession?.startTime
        )}`,
        `Testing stopped at: ${formatTime(
          reportData?.monitoringSession?.stopTime
        )}`,
      ],
    ];

    doc.autoTable({
      startY: contentStartY + 5,
      head: [],
      body: basicInfo,
      theme: "plain",
      styles: { fontSize: 10 },
      columnStyles: {
        0: { cellWidth: "auto" },
        1: { cellWidth: "auto" },
        2: { cellWidth: "auto" },
      },
    });
    const tableHeaders = [
      [
        { content: "Sr. No", rowSpan: 2 },
        { content: "Time in Hr", rowSpan: 2 },
        { content: "Input Speed", rowSpan: 2 },
        { content: "Output Speed", rowSpan: 2 },
        { content: "Actual Ratio", rowSpan: 2 },
        { content: "Vibration at input side (4.5mm/sec Max)", colSpan: 3 },
        {
          content: "Vibration at input side (4.5mm/sec Max)(opposite)",
          colSpan: 3,
        },
        { content: "Vibration at output side (4.5mm/sec Max)", colSpan: 3 },
        {
          content: "Vibration at output side (4.5mm/sec Max)(opposite)",
          colSpan: 3,
        },
        { content: "NOISE LEVEL (85dBA max at 1mtr dist.)", rowSpan: 2 },
        { content: "AMB. TEMP. (°C)", rowSpan: 2 },
        { content: "Bearing Cover Temp in (°C) Nearer to bearing", colSpan: 8 },
        { content: "Body Temp (°C) Input Side", rowSpan: 2 },
        { content: "Oil Temp (°C)", rowSpan: 2 },
        { content: "Oil Temp Rise (45°C max)", rowSpan: 2 },
        { content: "Direction of Rotation", rowSpan: 2 },
        { content: "FLS/Pump Pressure", rowSpan: 2 },
        { content: "Result (OK/ NOT OK)", rowSpan: 2 },
      ],
      [
        "V mm/s",
        "H mm/s",
        "A mm/s",
        "V mm/s",
        "H mm/s",
        "A mm/s",
        "V mm/s",
        "H mm/s",
        "A mm/s",
        "V mm/s",
        "H mm/s",
        "A mm/s",
        "I/P side",
        "O/P side",
        "I/P side",
        "O/P side",
        "I/P side",
        "O/P side",
        "I/P side",
        "O/P side",
      ],
    ];

    const pdfTableData = transformSensorData(tData, bData || []).map((row) => [
      row.srNo,
      row.timeInHr,
      row.inputSpeed,
      row.outputRatio,
      row.actual,
      ...row.vibrationInputSide,
      ...row.vibrationInputOpposite,
      ...row.vibrationOutputSide,
      ...row.vibrationOutputOpposite,
      row.noiseLevel,
      row.ambTemp,
      ...row.bearingCoverTemp,
      row.bodyTempInputSide,
      row.oilTemp,
      row.oilTempRise,
      row.directionOfRotation,
      row.flsPumpPressure,
      row.result,
    ]);

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 10,
      head: tableHeaders,
      body: pdfTableData,
      theme: "grid",
      styles: { fontSize: 8 },
      headStyles: { fillColor: [66, 66, 66] },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 20 },
      },
    });

    const leakageInfo = [
      ["Leakage Information:"],
      [
        `1) Leakage from Oil seal: ${
          reportData?.monitoringSession?.leakageSeal !== null
            ? reportData?.monitoringSession?.leakageSeal
              ? "Yes"
              : "No"
            : "-"
        }`,
      ],
      [
        `2) Leakage from cover/catcher face: ${
          reportData?.monitoringSession?.leakageCover !== null
            ? reportData?.monitoringSession?.leakageCover
              ? "Yes"
              : "No"
            : "-"
        }`,
      ],
      [
        `3) Leakage from cojoint face: ${
          reportData?.monitoringSession?.leakageGasket !== null
            ? reportData?.monitoringSession?.leakageGasket
              ? "Yes"
              : "No"
            : "-"
        }`,
      ],
      [
        `4) Leakage from breather: ${
          reportData?.monitoringSession?.leakageBreather !== null
            ? reportData?.monitoringSession?.leakageBreather
              ? "Yes"
              : "No"
            : "-"
        }`,
      ],
    ];

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 10,
      body: leakageInfo,
      theme: "plain",
      styles: { fontSize: 10 },
    });

    const remarksAndResults = [
      [`Remarks: ${reportData?.monitoringSession?.remarks ?? ""}`],
      [`NO LOAD TESTING Result: ${getTestResult(reportData?.colorIndicator)}`],
      [`Prepared by: ${reportData?.monitoringSession?.preparedBy ?? ""}`],
      ["This is a system generated Report No Signature required"],
    ];

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 10,
      body: remarksAndResults,
      theme: "plain",
      styles: { fontSize: 10 },
    });

    const fileName = `${
      reportData?.gearboxInfo?.gearboxSerialNo || "software-report"
    }.pdf`;
    doc.save(fileName);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await gearBoxApi.getCustomerDetails();
        // setReportData(response.data[0].gearboxReportDetails);
        console.log("report data:", response.data[0].gearboxReportDetails);
      
          setReportData(response.data[0].gearboxReportDetails);
        
      }catch (error) {
        // Access the error response from the server
        if (error.response) {
          console.log("Server error message:", error.response.data.message);
          setErrorMessage(error.response.data.message);
          setShowErrorDialog(true);
        } else if (error.request) {
          console.log("No response from server:", error.request);
          setErrorMessage("No response from the server. Please try again.");
          setShowErrorDialog(true);
        } else {
          console.log("Error during setup:", error.message);
          setErrorMessage("An unexpected error occurred. Please try again.");
          setShowErrorDialog(true);
        }
      }
    };

    fetchData();
  }, []);
  const handleNavigateToMonitoring = () => {
    navigate("/ptpl/ptplmonitoring");
  };
  const handleCloseDialog = () => {
    setShowErrorDialog(false);
  };
  const formatTime = (timeString) => {
    if (!timeString) return "-";
    try {
      return timeString.split(" ")[1] ?? "-";
    } catch (error) {
      return "-";
    }
  };
  const formatDay = (timestamp) => {
    if (!timestamp) return "";

    const date = new Date(timestamp.replace(/-/g, " "));

    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };

    return date.toLocaleDateString("en-GB", options);
  };
  const getTestResult = (colorIndicator) => {
    return colorIndicator === "RED" ? "Rejected" : "Accepted";
  };
  const transformSensorData = (reportSystemData, reportBearingData = []) => {
    if (!Array.isArray(reportSystemData)) {
      return [];
    }

    const formatValue = (value) => {
      return value === null || value === undefined ? "-" : value;
    };

    const bearingDataByTime = reportBearingData.reduce((acc, bearing) => {
      if (!acc[bearing.readingTime]) {
        acc[bearing.readingTime] = {};
      }
      acc[bearing.readingTime][bearing.bearingPosition] = bearing;
      return acc;
    }, {});

    return reportSystemData.map((systemData, index) => {
      const timeStampBearings = bearingDataByTime[systemData.readingTime] || {};

      const bearing1 = timeStampBearings["Bearing 1"] || {};
      const bearing2 = timeStampBearings["Bearing 2"] || {};
      const bearing3 = timeStampBearings["Bearing 3"] || {};
      const bearing4 = timeStampBearings["Bearing 4"] || {};
      const bearing5 = timeStampBearings["Bearing 5"] || {};
      const bearing6 = timeStampBearings["Bearing 6"] || {};
      const bearing9 = timeStampBearings["Bearing 9"] || {};
      const bearing10 = timeStampBearings["Bearing 10"] || {};

      const vibrationInputSide = [
        formatValue(bearing1.velocityY),
        formatValue(bearing1.velocityX),
        formatValue(bearing1.velocityZ),
      ];

      const vibrationInputOpposite = [
        formatValue(bearing2.velocityY),
        formatValue(bearing2.velocityX),
        formatValue(bearing2.velocityZ),
      ];

      const vibrationOutputSide = [
        formatValue(bearing10.velocityY),
        formatValue(bearing10.velocityX),
        formatValue(bearing10.velocityZ),
      ];

      const vibrationOutputOpposite = [
        formatValue(bearing9.velocityY),
        formatValue(bearing9.velocityX),
        formatValue(bearing9.velocityZ),
      ];

      const bearingCoverTemp = [
        formatValue(bearing1.temperature),
        "-",
        formatValue(bearing2.temperature),
        "-",
        formatValue(bearing9.temperature),
        "-",
        formatValue(bearing10.temperature),

        "-",
      ];

      const result =
        reportData?.monitoringSession?.colorIndicator === "GREEN"
          ? "OK"
          : "NOT OK";

      return {
        srNo: index + 1,
        timeInHr: formatValue(systemData.readingTime),
        inputSpeed: formatValue(systemData.inputRpm),
        outputRatio: formatValue(systemData.outputRpm),
        actual: formatValue(systemData.actualRatio),
        vibrationInputSide,
        vibrationInputOpposite,
        vibrationOutputSide,
        vibrationOutputOpposite,
        noiseLevel: formatValue(systemData.overallNoise),
        ambTemp: formatValue(systemData.ambientTemperature),
        bearingCoverTemp,
        bodyTempInputSide: formatValue(systemData.systemTemperature),
        oilTemp: formatValue(systemData.oilTemperature),
        oilTempRise: formatValue(systemData.temperatureRise),
        directionOfRotation: formatValue(systemData.directionOfRotation),
        flsPumpPressure: formatValue(systemData.flsPumpPressure),
        result,
      };
    });
  };
  const tData = reportData?.reportSystemData || [];
  const bData = reportData?.reportBearingData || [];
  console.log("tdata:,bdata:", tData, bData);
  const tableData = transformSensorData(tData, bData);

  return (
    <>
     {!showErrorDialog && (
    <TableContainer component={Paper} style={{ padding: "10px" }}>
      <Box sx={{ p: 2, backgroundColor: "white", color: "white" }}>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <TextField
              label="PTPL Order No:"
              variant="filled"
              size="small"
              value={reportData?.gearboxInfo?.ptplOrderNo ?? "-"}
              sx={{ backgroundColor: "white", width: "100% !important" }}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label="Date:"
              variant="filled"
              size="small"
              value={formatDay(reportData?.monitoringSession?.startTime)}
              sx={{ backgroundColor: "white", width: "100% !important" }}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label="Shift Gen:"
              variant="filled"
              size="small"
              value={reportData?.monitoringSession?.shift ?? "-"}
              sx={{ backgroundColor: "white", width: "100% !important" }}
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="space-between" mt={1}>
          <TextField
            label="Customer:"
            variant="filled"
            size="small"
            value={reportData?.gearboxInfo?.customerName || "-"}
            sx={{ backgroundColor: "white", mr: 1, flexGrow: 1 }}
          />
          <TextField
            label="Gearbox Box Sr. No:"
            variant="filled"
            size="small"
            value={reportData?.gearboxInfo?.gearboxSerialNo ?? "-"}
            sx={{ backgroundColor: "white", flexGrow: 1, mr: 1 }}
          />
          <TextField
            label="Gearbox Description:"
            variant="filled"
            size="small"
            value={reportData?.gearboxInfo?.gearboxNamePartNo ?? "-"}
            sx={{ backgroundColor: "white", flexGrow: 1 }}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" mt={1}>
          <TextField
            label="Plant/Unit details:"
            variant="filled"
            size="small"
            value={reportData?.gearboxInfo?.unitDetail || "-"}
            sx={{ backgroundColor: "white", mr: 1 }}
          />
          <TextField
            label="Ratio:"
            variant="filled"
            size="small"
            value={`${reportData?.gearboxInfo?.ratio || "-"}`}
            sx={{ backgroundColor: "white", mr: 1 }}
          />
          <TextField
            label="Req I/P speed:"
            variant="filled"
            size="small"
            value={`${reportData?.monitoringSession?.inputRpm || "-"} RPM`}
            sx={{ backgroundColor: "white", mr: 1 }}
          />
          <TextField
            label="FLS/Pump Details-Make:"
            variant="filled"
            size="small"
            value={reportData?.gearboxInfo?.flsPumpDetails ?? "NA"}
            sx={{ backgroundColor: "white", mr: 1 }}
          />
          <TextField
            label="Capacity:"
            variant="filled"
            size="small"
            value={reportData?.gearboxInfo?.flsCapacity ?? "NA"}
            sx={{ backgroundColor: "white", mr: 1 }}
          />
          <TextField
            label="Drawing No:"
            variant="filled"
            size="small"
            value={reportData?.gearboxInfo?.drawingNo ?? "NA"}
            sx={{ backgroundColor: "white" }}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" mt={1}>
          <TextField
            label="Testing started at:"
            variant="filled"
            size="small"
            value={formatTime(reportData?.monitoringSession?.startTime)}
            sx={{ backgroundColor: "white", mr: 1, flexGrow: 1 }}
          />
          <TextField
            label="Testing stopped at:"
            variant="filled"
            size="small"
            value={formatTime(reportData?.monitoringSession?.stopTime)}
            sx={{ backgroundColor: "white", flexGrow: 1 }}
          />
        </Box>
      </Box>

      <Box sx={{ width: "100%", overflowX: "auto" }}>
        <StyledTableContainer component={Paper}>
          <Table
            stickyHeader
            sx={{ minWidth: 2000 }}
            size="small"
            aria-label="software report table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell rowSpan={2}>Sr. No</StyledTableCell>
                <StyledTableCell rowSpan={2}>Time in Hr</StyledTableCell>
                <StyledTableCell rowSpan={2}>Input Speed</StyledTableCell>
                <StyledTableCell rowSpan={2}>Output Speed</StyledTableCell>
                <StyledTableCell rowSpan={2}>Actual Ratio</StyledTableCell>
                <StyledTableCell colSpan={3}>
                  Vibration at input side (4.5mm/sec Max)
                </StyledTableCell>
                <StyledTableCell colSpan={3}>
                  Vibration at input side (4.5mm/sec Max)(opposite)
                </StyledTableCell>
                <StyledTableCell colSpan={3}>
                  Vibration at output side (4.5mm/sec Max)
                </StyledTableCell>
                <StyledTableCell colSpan={3}>
                  Vibration at output side (4.5mm/sec Max)(opposite)
                </StyledTableCell>
                <StyledTableCell rowSpan={2}>
                  <Typography variant="caption">
                    NOISE LEVEL (85dBA max at 1mtr dist.)
                  </Typography>
                </StyledTableCell>
                <StyledTableCell rowSpan={2}>
                  <Typography variant="caption">AMB. TEMP. (°C)</Typography>
                </StyledTableCell>
                <StyledTableCell colSpan={8}>
                  Bearing Cover Temp in (°C) Nearer to bearing
                </StyledTableCell>
                <StyledTableCell rowSpan={2}>
                  <Typography variant="caption">
                    Body Temp (°C) Input Side
                  </Typography>
                </StyledTableCell>
                <StyledTableCell rowSpan={2}>
                  <Typography variant="caption">Oil Temp (°C)</Typography>
                </StyledTableCell>
                <StyledTableCell rowSpan={2}>
                  <Typography variant="caption">
                    Oil Temp Rise (45°C max)
                  </Typography>
                </StyledTableCell>
                <StyledTableCell rowSpan={2}>
                  <Typography variant="caption">
                    Direction of Rotation
                  </Typography>
                </StyledTableCell>
                <StyledTableCell rowSpan={2}>
                  <Typography variant="caption">FLS/Pump Pressure</Typography>
                </StyledTableCell>

                <StyledTableCell rowSpan={2}>
                  <Typography variant="caption">Result (OK/ NOT OK)</Typography>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell colSpan={1}>Vertical mm/sec</StyledTableCell>
                <StyledTableCell colSpan={1}>Horizontal mm/sec</StyledTableCell>
                <StyledTableCell colSpan={1}>Axial mm/sec</StyledTableCell>
                <StyledTableCell colSpan={1}>Vertical mm/sec</StyledTableCell>
                <StyledTableCell colSpan={1}>Horizontal mm/sec</StyledTableCell>
                <StyledTableCell colSpan={1}>Axial mm/sec</StyledTableCell>{" "}
                <StyledTableCell colSpan={1}>Vertical mm/sec</StyledTableCell>
                <StyledTableCell colSpan={1}>Horizontal mm/sec</StyledTableCell>
                <StyledTableCell colSpan={1}>Axial mm/sec</StyledTableCell>
                <StyledTableCell colSpan={1}>Vertical mm/sec</StyledTableCell>
                <StyledTableCell colSpan={1}>Horizontal mm/sec</StyledTableCell>
                <StyledTableCell colSpan={1}>Axial mm/sec</StyledTableCell>
                <StyledTableCell colSpan={2}>I/P</StyledTableCell>
                <StyledTableCell colSpan={2}> II stage</StyledTableCell>
                <StyledTableCell colSpan={2}> III stage</StyledTableCell>
                <StyledTableCell colSpan={2}>O/P</StyledTableCell>
              </TableRow>
              <TableRow>
                {Array(19)
                  .fill(null)
                  .map((_, index) => (
                    <StyledTableCell key={`empty-${index}`}></StyledTableCell>
                  ))}

                <StyledTableCell>I/P side</StyledTableCell>
                <StyledTableCell>O/P side</StyledTableCell>
                <StyledTableCell>I/P side</StyledTableCell>
                <StyledTableCell>O/P side</StyledTableCell>
                <StyledTableCell>I/P side</StyledTableCell>
                <StyledTableCell>O/P side</StyledTableCell>
                <StyledTableCell>I/P side</StyledTableCell>
                <StyledTableCell>O/P side</StyledTableCell>
                {Array(6)
                  .fill(null)
                  .map((_, index) => (
                    <StyledTableCell key={`empty-${index}`}></StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, index) => (
                <TableRow key={index}>
                  <StyledTableCell>{row.srNo}</StyledTableCell>
                  <StyledTableCell>{row.timeInHr}</StyledTableCell>

                  <StyledTableCell>{row.inputSpeed}</StyledTableCell>
                  <StyledTableCell>{row.outputRatio}</StyledTableCell>
                  <StyledTableCell>{row.actual}</StyledTableCell>
                  {row.vibrationInputSide.map((value, i) => (
                    <StyledTableCell key={`vibIn-${i}`}>
                      {value}
                    </StyledTableCell>
                  ))}
                  {row.vibrationInputOpposite.map((value, i) => (
                    <StyledTableCell key={`vibInOpp-${i}`}>
                      {value}
                    </StyledTableCell>
                  ))}
                  {row.vibrationOutputSide.map((value, i) => (
                    <StyledTableCell key={`vibOut-${i}`}>
                      {value}
                    </StyledTableCell>
                  ))}
                  {row.vibrationOutputOpposite.map((value, i) => (
                    <StyledTableCell key={`vibOutOpp-${i}`}>
                      {value}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell>{row.noiseLevel}</StyledTableCell>
                  <StyledTableCell>{row.ambTemp}</StyledTableCell>
                  {row.bearingCoverTemp.map((value, i) => (
                    <StyledTableCell key={`bearingTemp-${i}`}>
                      {value}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell>{row.bodyTempInputSide}</StyledTableCell>
                  <StyledTableCell>{row.oilTemp}</StyledTableCell>
                  <StyledTableCell>{row.oilTempRise}</StyledTableCell>
                  <StyledTableCell>{row.directionOfRotation}</StyledTableCell>
                  <StyledTableCell>{row.flsPumpPressure}</StyledTableCell>
                  <StyledTableCell>{row.result}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>

      <Box sx={{ p: 2, borderTop: "1px solid rgba(224, 224, 224, 1)" }}>
        <Typography variant="subtitle1">Leakage Area:</Typography>
        <Box display="flex" justifyContent="space-between">
          <TextField
            label="1) Leakage from Oil seal - Yes/No"
            value={
              reportData?.monitoringSession?.leakageSeal !== null
                ? reportData?.monitoringSession?.leakageSeal
                  ? "Yes"
                  : "No"
                : "-"
            }
            variant="outlined"
            size="small"
            fullWidth
            sx={{ mr: 1 }}
          />
          <TextField
            label="2) Leakage from cover/catcher face - Yes/No"
            value={
              reportData?.monitoringSession?.leakageCover !== null
                ? reportData?.monitoringSession?.leakageCover
                  ? "Yes"
                  : "No"
                : "-"
            }
            variant="outlined"
            size="small"
            fullWidth
            sx={{ mr: 1 }}
          />
          <TextField
            label="3) Leakage from cojoint face - Yes/No"
            value={
              reportData?.monitoringSession?.leakageGasket !== null
                ? reportData?.monitoringSession?.leakageGasket
                  ? "Yes"
                  : "No"
                : "-"
            }
            variant="outlined"
            size="small"
            fullWidth
            sx={{ mr: 1 }}
          />
          <TextField
            label="4) Leakage from breather - Yes/No"
            value={
              reportData?.monitoringSession?.leakageBreather !== null
                ? reportData?.monitoringSession?.leakageBreather
                  ? "Yes"
                  : "No"
                : "-"
            }
            variant="outlined"
            size="small"
            fullWidth
          />
        </Box>
      </Box>

      <Box sx={{ p: 2, borderTop: "1px solid rgba(224, 224, 224, 1)" }}>
        <TextField
          label="Remarks"
          value={reportData?.monitoringSession?.remarks ?? ""}
          variant="outlined"
          size="small"
          fullWidth
          sx={{ mb: 1 }}
        />
        <TextField
          label="NO LOAD TESTING Result: Accepted / Rejected"
          value={reportData?.monitoringSession?.noLoadTestingResult || ""}
          variant="outlined"
          size="small"
          fullWidth
          sx={{
            mb: 1,
            "& .MuiInputBase-input": {
              color:
                reportData?.monitoringSession?.colorIndicator === "RED"
                  ? "red"
                  : "green",
              fontWeight: "bold",
            },
          }}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true, // Ensures the label stays above the input field
          }}
        />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <TextField
            label="Prepared by:"
            variant="outlined"
            value={reportData?.monitoringSession?.preparedBy ?? "-"}
            size="full-width"
          />
          <Typography>
            This is a system generated Report No Signature required
          </Typography>
          <Button variant="contained" onClick={downloadPDF}>
            Download As PDF
          </Button>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography />
        </Box>
      </Box>
    </TableContainer>
    )}
    <Dialog
        open={showErrorDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Report Generation Error"}
        </DialogTitle>
        <DialogContent>
          <Alert severity="error" sx={{ mb: 2 }}>
            {errorMessage}
          </Alert>
          <DialogContentText id="alert-dialog-description">
            Please complete all required fields in the monitoring section to generate the report.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
          <Button 
            onClick={handleNavigateToMonitoring} 
            variant="contained" 
            color="primary" 
            autoFocus
          >
            Go to Monitoring
          </Button>
        </DialogActions>
      </Dialog>

   </>
  );
};

export default SoftwareReportTable;
