import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Button,
  Paper,
  Typography,
  Stack,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import { Settings, Person, ArrowForward } from "@mui/icons-material";
import { gearBoxApi } from "../../api/PTPL API/gearBoxApi";
import { useNavigate } from "react-router-dom";
import { bearingApi } from "../../api/PTPL API/bearingApi";
import { partsApi } from "../../api/PTPL API/partsApi";
import { customerApi } from "../../api/PTPL API/customerApi";

const PTPLSettings = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [bearingData, setBearingData] = useState([]);
  const [partsData,setPartData]=useState([])
  const [customerData,setCustomerData]=useState([])
  const [filteredGearboxes, setFilteredGearboxes] = useState([]);
  const [formData, setFormData] = useState({
    serialNo: "",
    gearboxName: "",
    operator:localStorage.getItem("fullName"),
    bearings: {
      bearing1: { bearingPosition: "Bearing 1", deviceNo: "1" },
      bearing2: { bearingPosition: "Bearing 2", deviceNo: "" },
      bearing3: { bearingPosition: "Bearing 3", deviceNo: "" },
      bearing4: { bearingPosition: "Bearing 4", deviceNo: "" },
      bearing5: { bearingPosition: "Bearing 5", deviceNo: "" },
      bearing6: { bearingPosition: "Bearing 6", deviceNo: "" },
      bearing7: { bearingPosition: "Bearing 7", deviceNo: "" },
      bearing8: { bearingPosition: "Bearing 8", deviceNo: "" },
      bearing9: { bearingPosition: "Bearing 9", deviceNo: "" },
      bearing10: { bearingPosition: "Bearing 10", deviceNo: "10" },
    },
    ptplOrderNo: "",
    customerName: "",
    unitDetail: "2110",
    flsPumpDetails: "",
    flsCapacity: "",
    drawingNo: "",
    flsPumpSrNo: "",
    gearboxDescription:''
  });

  useEffect(() => {
    fetchBearingDetails();
    fetchPartDetails()
    fetchCustomerDetails()
  }, []);
  useEffect(() => {
    if (formData.ptplOrderNo) {
      const filteredBoxes = partsData.filter(
        part => part.orderNumber === formData.ptplOrderNo
      );
      setFilteredGearboxes(filteredBoxes);
      
      // Clear gearbox selection if current selection doesn't match the order number
      const currentGearboxExists = filteredBoxes.some(
        box => box.gearBoxNumber === formData.gearboxName
      );
      if (!currentGearboxExists) {
        setFormData(prev => ({
          ...prev,
          gearboxName: "",
          customerName: "",
          drawingNo: "",
          flsPumpDetails: "",
          flsPumpSrNo: "",
          flsCapacity: "",
          gearboxDescription: ""
        }));
      }
    } else {
      setFilteredGearboxes([]);
    }
  }, [formData.ptplOrderNo, partsData]);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));

    if ((name === 'ptplOrderNo' || name === 'gearboxName')) {
      const updatedFormData = {
        ...formData,
        [name]: value
      };
      
      if (updatedFormData.ptplOrderNo && updatedFormData.gearboxName) {
        await fetchPartDetailsBySerialNumber(updatedFormData.ptplOrderNo, updatedFormData.gearboxName);
      }
    }
  };

  const getOriginalBearingPosition = (displayPosition) => {
    if (displayPosition === "Bearing 1 (Main Input)") return "Bearing 1";
    if (displayPosition === "Bearing 10 (Main Output)") return "Bearing 10";
    return displayPosition;
  };
  const getSelectedDeviceNumbers = (currentBearingKey) => {
    return Object.entries(formData.bearings)
      .filter(([key, bearing]) => key !== currentBearingKey && bearing.deviceNo)
      .map(([_, bearing]) => bearing.deviceNo);
  };
  
  const fetchBearingDetails = async () => {
    try {
      const response = await bearingApi.getBearingInfo();
      console.log("bearing data:", response.data);
      setBearingData(response.data);
    } catch (error) {
      console.error("Error fetching bearing data:", error);
    }
  };
  const fetchPartDetails=async()=>{
    try {
      const response =await partsApi.getParts()
      console.log("parts data:",response.data)
      setPartData(response.data)
    } catch (error) {
      console.error("error getting parts api:",error)
    }
  }
  const fetchPartDetailsBySerialNumber = async (orderNumber, gearboxNumber) => {
    try {
      const response = await partsApi.partDetails({
        orderNumber: orderNumber,
        gearboxSerialNumber: gearboxNumber
      });
      
      // Auto-populate form fields with API response data
      if (response.data && response.data.length > 0) {
        const partDetails = response.data[0];
        setFormData(prev => ({
          ...prev,
          customerName: partDetails.customerName,
          drawingNo: partDetails.gaDrawingNumber,
          flsPumpDetails: partDetails.flsPumpDetailsMake,
          flsPumpSrNo: partDetails.flsPumpSerialNumber,
          flsCapacity: partDetails.capacity,gearboxName:partDetails.gearBoxNumber,gearboxDescription:partDetails.gearboxDescription
        }));
      }
    } catch (error) {
      console.error("Error fetching part details:", error);
      setNotification({
        open: true,
        message: "Error fetching part details",
        severity: "error"
      });
    }
  };
  const fetchCustomerDetails=async()=>{
    try {
      const response =await customerApi.getCustomer()
      setCustomerData(response.data)
      console.log("customer data:",response.data)
    } catch (error) {
      console.error("error getting customer api:",error)
    }
  }
  const handleBearingChange = (bearingKey, deviceNo) => {
    setFormData((prev) => ({
      ...prev,
      bearings: {
        ...prev.bearings,
        [bearingKey]: {
          ...prev.bearings[bearingKey],
          deviceNo: deviceNo,
        },
      },
    }));
  };

  const handleCloseNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  const formatBearingDataForApi = () => {
    return Object.values(formData.bearings)
      .filter((bearing) => bearing.deviceNo)
      .map(({ bearingPosition, deviceNo }) => ({
        bearingPosition,
        deviceNo,
      }));
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);

      const apiData = {
        gearboxSerialNo: formData.gearboxName,
        gearboxNamePartNo: formData.gearboxName,
        ptplOrderNo: formData.ptplOrderNo,
        customerName: formData.customerName,
        unitDetail: formData.unitDetail,
        drawingNo: formData.drawingNo,
        flsPumpDetails: formData.flsPumpDetails,
        flsCapacity: formData.flsCapacity,
        flsPumpSrNo: formData.flsPumpSrNo,
        bearingData: formatBearingDataForApi(),
      };

      const data = await gearBoxApi.addGearBoxInfo(apiData);
      console.log(data.message);
      setNotification({
        open: true,
        message: "Gearbox information saved successfully!",
        severity: "success",
      });
      navigate(`/ptpl/ptplmonitoring`);
    } catch (error) {
      console.log("error message", error.message);
      setNotification({
        open: true,
        message:
          error.response?.data?.message ||
          error.message ||
          "Failed to save gearbox information",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };
console.log("parts data:",partsData)
  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        m: 2,
        borderRadius: 2,
        bgcolor: "#f8f9fa",
      }}
    >
      <Box
        sx={{
          p: 2,
          mb: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: "#fff",
          borderRadius: 1,
          boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Settings sx={{ fontSize: 28, color: "#1976d2" }} />
          <Typography variant="h5" sx={{ fontWeight: 600, color: "#2c3e50" }}>
            Setting Page
          </Typography>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{
            mr: 2,
            bgcolor: "#f1f8ff",
            p: 1,
            borderRadius: 1,
          }}
        >
          <Person sx={{ fontSize: 24, color: "#1976d2" }} />
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 600, color: "#2c3e50" }}
          >
            {formData.operator}
          </Typography>
        </Stack>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>PTL Order No.</InputLabel>
                <Select
                  label="PTL Order No."
                  name="ptplOrderNo"
                  value={formData.ptplOrderNo}
                  onChange={handleChange}
                  sx={{ bgcolor: "#fff" }}
                >
                  {partsData?.map((part) => (
                    <MenuItem 
                      key={part.id} 
                      value={`${part.orderNumber}`}
                    >
                      {`${part.orderNumber} `}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Gearbox Serial No.</InputLabel>
                <Select
                  label="Gearbox Serial No."
                  name="gearboxName"
                  value={formData.gearboxName}
                  onChange={handleChange}
                  sx={{ bgcolor: "#fff" }}
                >
                    {filteredGearboxes.map((part) => (
                    <MenuItem 
                      key={part.id} 
                      value={part.gearBoxNumber}
                    >
                      {part.gearBoxNumber}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ bgcolor: "#1976d2", opacity: 0.2 }} />
        </Grid>
        {/* <Grid item xs={12}>
          <Box sx={{ bgcolor: '#fff', p: 2, borderRadius: 1 }}>
            <Grid container spacing={2}>
              {Object.entries(formData.bearings).map(([key, bearing]) => {
                const selectedDevices = getSelectedDeviceNumbers(key);
                
                return (
                  <Grid item xs={12} sm={6} md={3} key={key}>
                    <FormControl fullWidth>
                      <InputLabel>{bearing.bearingPosition}</InputLabel>
                      <Select
                        label={bearing.bearingPosition}
                        value={bearing.deviceNo || ''}
                        onChange={(e) => handleBearingChange(key, e.target.value)}
                      >
                        {bearingData
                          .filter(option => 
                            option.bearing_position === bearing.bearingPosition &&
                            !selectedDevices.includes(option.device_no)
                          )
                          .map((option) => (
                            <MenuItem key={option.device_no} value={option.device_no}>
                              {option.device_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid> */}
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                bgcolor: "#fff",
                p: 2,
                borderRadius: 2,
                mb: 1,
                width: "100%",
                border: "1px solid black",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: 600, color: "#2c3e50", mb: 1 }}
              >
                Main Input Shaft
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Bearing 1 (Main Input Side)</InputLabel>
                    <Select
                      label="Bearing 1 (Main Input Side)"
                      value={formData.bearings.bearing1.deviceNo || ""}
                      onChange={(e) =>
                        handleBearingChange("bearing1", e.target.value)
                      }
                    >
                      {bearingData
                        .filter(
                          (option) =>
                            option.bearing_position === "Bearing 1" &&
                            !getSelectedDeviceNumbers("bearing1").includes(
                              option.device_no
                            )
                        )
                        .map((option) => (
                          <MenuItem
                            key={option.device_no}
                            value={option.device_no}
                          >
                            {option.device_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Bearing 2 (Opposite Side)</InputLabel>
                    <Select
                      label="Bearing 2 (Opposite Side)"
                      value={formData.bearings.bearing2.deviceNo || ""}
                      onChange={(e) =>
                        handleBearingChange("bearing2", e.target.value)
                      }
                    >
                      {bearingData
                        .filter(
                          (option) =>
                            option.bearing_position === "Bearing 2" &&
                            !getSelectedDeviceNumbers("bearing2").includes(
                              option.device_no
                            )
                        )
                        .map((option) => (
                          <MenuItem
                            key={option.device_no}
                            value={option.device_no}
                          >
                            {option.device_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                bgcolor: "#fff",
                p: 2,
                borderRadius: 2,
                mb: 1,
                border: "1px solid black",
                width: "100%",
                marginLeft: "2px",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: 600, color: "#2c3e50", mb: 1 }}
              >
                Main Output Shaft
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Bearing 9 (Opposite Side)</InputLabel>
                    <Select
                      label="Bearing 9 (Opposite Side)"
                      value={formData.bearings.bearing9.deviceNo || ""}
                      onChange={(e) =>
                        handleBearingChange("bearing9", e.target.value)
                      }
                    >
                      {bearingData
                        .filter(
                          (option) =>
                            option.bearing_position === "Bearing 9" &&
                            !getSelectedDeviceNumbers("bearing9").includes(
                              option.device_no
                            )
                        )
                        .map((option) => (
                          <MenuItem
                            key={option.device_no}
                            value={option.device_no}
                          >
                            {option.device_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Bearing 10 (Main Output Side)</InputLabel>
                    <Select
                      label="Bearing 10 (Main Output Side)"
                      value={formData.bearings.bearing10.deviceNo || ""}
                      onChange={(e) =>
                        handleBearingChange("bearing10", e.target.value)
                      }
                    >
                      {bearingData
                        .filter(
                          (option) =>
                            option.bearing_position === "Bearing 10" &&
                            !getSelectedDeviceNumbers("bearing10").includes(
                              option.device_no
                            )
                        )
                        .map((option) => (
                          <MenuItem
                            key={option.device_no}
                            value={option.device_no}
                          >
                            {option.device_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </div>

          <Box sx={{ bgcolor: "#fff", p: 2, borderRadius: 1 }}>
            <Grid container spacing={2}>
              {Object.entries(formData.bearings)
                .filter(
                  ([key]) =>
                    !["bearing1", "bearing2", "bearing9", "bearing10"].includes(
                      key
                    )
                )
                .map(([key, bearing]) => {
                  const selectedDevices = getSelectedDeviceNumbers(key);

                  return (
                    <Grid item xs={12} sm={6} md={3} key={key}>
                      <FormControl fullWidth>
                        <InputLabel>{bearing.bearingPosition}</InputLabel>
                        <Select
                          label={bearing.bearingPosition}
                          value={bearing.deviceNo || ""}
                          onChange={(e) =>
                            handleBearingChange(key, e.target.value)
                          }
                        >
                          {bearingData
                            .filter(
                              (option) =>
                                option.bearing_position ===
                                  bearing.bearingPosition &&
                                !selectedDevices.includes(option.device_no)
                            )
                            .map((option) => (
                              <MenuItem
                                key={option.device_no}
                                value={option.device_no}
                              >
                                {option.device_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
        </Grid>
        
        <Grid item xs={12}>
          <Divider sx={{ bgcolor: "#1976d2", opacity: 0.2 }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
          <TextField
              fullWidth
              label="Gearbox Description"
              variant="outlined"
              name=""
              value={formData.gearboxDescription}
              onChange={handleChange}
              sx={{ "& .MuiOutlinedInput-root": { bgcolor: "#fff" } }}
            />
           <TextField
              fullWidth
              label="Customer Name"
              variant="outlined"
              name="customerName"
              value={formData.customerName}
              onChange={handleChange}
              sx={{ "& .MuiOutlinedInput-root": { bgcolor: "#fff" } }}
            />
             <TextField
              fullWidth
              label="Unit Detail"
              variant="outlined"
              name="unitDetail"
              value={formData.unitDetail}
              onChange={handleChange}
              sx={{ "& .MuiOutlinedInput-root": { bgcolor: "#fff" } }}
            />
            <TextField
              fullWidth
              label="FLS Pump Sr. No."
              variant="outlined"
              name="flsPumpSrNo"
              value={formData.flsPumpSrNo}
              onChange={handleChange}
              sx={{ "& .MuiOutlinedInput-root": { bgcolor: "#fff" } }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
        
          <Stack spacing={2}>
          <TextField
              fullWidth
              label="FLS Capacity"
              variant="outlined"
              name="flsCapacity"
              value={formData.flsCapacity}
              onChange={handleChange}
              sx={{ "& .MuiOutlinedInput-root": { bgcolor: "#fff" } }}
            />
         <TextField
              fullWidth
              label="GA Drawing No."
              variant="outlined"
              name="drawingNo"
              value={formData.drawingNo}
              onChange={handleChange}
              sx={{ "& .MuiOutlinedInput-root": { bgcolor: "#fff" } }}
            />
           <TextField
              fullWidth
              label="FLS Pump Details"
              variant="outlined"
              name="flsPumpDetails"
              value={formData.flsPumpDetails}
              onChange={handleChange}
              sx={{ "& .MuiOutlinedInput-root": { bgcolor: "#fff" } }}
            />
           
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
              disabled={loading}
              sx={{
                height: "56px",
                color: "white",
                textTransform: "none",
                fontSize: "1.1rem",
                fontWeight: 600,
                boxShadow: "0 4px 6px rgba(25, 118, 210, 0.25)",
                "&:hover": {
                  boxShadow: "0 6px 8px rgba(25, 118, 210, 0.35)",
                },
              }}
              endIcon={<ArrowForward />}
            >
              {loading ? "Saving..." : "Next"}
            </Button>
          </Stack>
        </Grid>
      </Grid>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default PTPLSettings;


