import { Navigate, useParams } from "react-router-dom";
import PTPLMonitoring from "./Monitoring";
import React from "react";
const PTPLMonitoringWrapper = () => {
    const { serialNo } = useParams();
    
    // You can add validation here if needed
    if (!serialNo) {
      return <Navigate to="/ptpl/ptplsettings" />;
    }
    
    return <PTPLMonitoring serialNo={serialNo} />;
  };

export default PTPLMonitoringWrapper