import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  Typography,
  Stack,
  Button,
  Grid,
  Divider,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Radio,
  FormControlLabel,
  RadioGroup,
  Snackbar,
  Alert,
} from "@mui/material";
import { Timer, PlayArrow, Stop, Monitor } from "@mui/icons-material";
import BearingDataTable from "./BearingTable";
import { gearBoxApi } from "../../api/PTPL API/gearBoxApi";
import { useNavigate } from "react-router-dom";
import { stoppageMasterApi } from "../../api/PTPL API/stoppageMaster";

const PTPLMonitoring = ({ serialNo }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(0);
  const [systemStatus, setSystemStatus] = useState("ok");
  const [monitoringData, setMonitoringData] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [gBoxSerialNo, setgBoxSerialNo] = useState("");
  const [stopageReason, setStopageReason] = useState("");
  const [stopData, setStopData] = useState({
    reason: "",
    remarks: "",
    leakageCover: false,
    leakageGasket: false,
    leakageSeal: false,
    leakageBreather: false,
  });
  const [parameters, setParameters] = useState({
    leakageCover: "",
    leakageGasket: "",
    leakageOilSeal: "",
    leakageBreather: "",
  });
  const [leakageData, setLeakageData] = useState({
    leakageCover: null,
    leakageGasket: null,
    leakageSeal: null,
    leakageBreather: null,
  });
  const [remarks, setRemarks] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("error");
  const [stopModalOpen, setStopModalOpen] = useState(false);
  const [stoppageReason, setStoppageReason] = useState([]);
  const handleChange = (event, parameter) => {
    console.log("Parameter changed:", parameter, "New value:", event.target.value);
    setParameters((prev) => ({
      ...prev,
      [parameter]: event.target.value,
    }));
  };
  const parametersList = [
    { id: "leakageCover", label: "Leakage From Cover/Catcher Face" },
    { id: "leakageGasket", label: "Leakage From Gasket Joints" },
    { id: "leakageOilSeal", label: "Leakage From Oil Seal" },
    { id: "leakageBreather", label: "Leakage From Breather" },
  ];

  useEffect(() => {
    let intervalId;
    if (isRunning) {
      intervalId = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [isRunning]);
  const handleStart = async () => {
    try {
      await gearBoxApi.addMonitoringSession({
        gearboxSerialNo: gBoxSerialNo,
        action: "START",
      });
      setIsRunning(true);

      fetchMonitoringData();
    } catch (error) {
      console.error("Error starting monitoring session:", error);
    }
  };

  const parseTimeToSeconds = (timeString) => {
    if (!timeString) return 0;
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const handleStop = async () => {
    try {
      await gearBoxApi.addMonitoringSession({
        gearboxSerialNo: gBoxSerialNo,
        action: "STOP",
      });
      setIsRunning(false);
      setStartTime(null);
      fetchMonitoringData();
      showAlert("Monitoring session stopped successfully", "success");
    } catch (error) {
      console.error("Error stopping monitoring session", error);
      showAlert("Failed to stop monitoring session", "error");
    }
  };

  const handleStopDataChange = (field, value) => {
    setStopData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (monitoringData?.monitoringSession) {
      const session = monitoringData.monitoringSession;
      setParameters({
        leakageCover: session.leakageCover || "",
        leakageGasket: session.leakageGasket || "",
        leakageOilSeal: session.leakageSeal || "",
        leakageBreather: session.leakageBreather || "",
      });

      setRemarks(session.remarks || "");
      setStopageReason(session.reason || "");
    }
  }, [monitoringData]);
  useEffect(() => {
    fetchStoppageReasons();
    const checkMonitoringStatus = async () => {
      fetchMonitoringData();
      try {
        const response = await gearBoxApi.getMonitoringData();
        if (response.status === "success" && response.data.length > 0) {
          const data = response.data[0].gearboxMonitoring;

          if (data?.monitoringSession?.buttonStatus === "Completed") {
            setTime(parseTimeToSeconds(data.monitoringSession.time));

            setLeakageData({
              leakageCover: data.monitoringSession.leakageCover,
              leakageGasket: data.monitoringSession.leakageGasket,
              leakageSeal: data.monitoringSession.leakageSeal,
              leakageBreather: data.monitoringSession.leakageBreather,
            });
          }

          if (data?.monitoringSession?.buttonStatus === "Inprogress") {
            setIsRunning(true);
            setTime(parseTimeToSeconds(data.monitoringSession.time));
          }

          setMonitoringData(data);
        }
      } catch (error) {
        console.error("Error fetching monitoring data:", error);
      }
    };

    checkMonitoringStatus();

    const intervalId = setInterval(checkMonitoringStatus, 60000);

    return () => clearInterval(intervalId);
  }, []);
  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  useEffect(() => {
    if (monitoringData?.monitoringSession) {
      const session = monitoringData.monitoringSession;
      setParameters({
        leakageCover: session.leakageCover || "",
        leakageGasket: session.leakageGasket || "",
        leakageOilSeal: session.leakageSeal || "",
        leakageBreather: session.leakageBreather || "",
      });
      setRemarks(session.remarks || "");
      setStopageReason(session.reason || "");
    }
  }, [monitoringData]);
  const fetchMonitoringData = async () => {
    try {
      const response = await gearBoxApi.getMonitoringData();
      if (response.status === "success" && response.data.length > 0) {
        const data = response.data[0].gearboxMonitoring;
        console.log("monitoring page data:", data);
        setMonitoringData(data);

        setgBoxSerialNo(data.monitoringSession.gearboxSerialNo);
        setSystemStatus(
          data.monitoringSession.status === "Green" ? "ok" : "error"
        );

        if (
          data.monitoringSession.startTime &&
          !data.monitoringSession.stopTime
        ) {
          const start = new Date(data.monitoringSession.startTime);
          const now = new Date();
          const diffInSeconds = Math.floor((now - start) / 1000);
          setTime(diffInSeconds);
        }
      }
    } catch (error) {
      console.error("Error fetching monitoring data:", error);
    }
  };
  const navigate = useNavigate();
  const handleNext = async () => {
    if (!parameters.leakageBreather || !parameters.leakageCover || 
      !parameters.leakageGasket || !parameters.leakageOilSeal) {
    showAlert("Please fill up all the leakage points before proceeding.", "error");
    return;
  }
    if (!stopData.reason) {
      showAlert("Please provide a reason before proceeding", "error");
      return;
    }

    try {
      const apiBody = {
        gearboxSerialNo: gBoxSerialNo,
        leakageCover: stopData.leakageCover === "yes",
        leakageGasket: stopData.leakageGasket === "yes",
        leakageSeal: stopData.leakageSeal === "yes",
        leakageBreather: stopData.leakageBreather === "yes",
        remarks: stopData.remarks,
        reason: stopData.reason,
      };

      await gearBoxApi.addMonitoringSession(apiBody);
      navigate("/ptpl/ptpltesting");
    } catch (error) {
      console.error("Error updating session data:", error);
      showAlert("Failed to update session data", "error");
    }
  };
  const fetchStoppageReasons = async () => {
    try {
      const result = await stoppageMasterApi.getStoppages();
      console.log("stoppage reasons:", result.data);
      setStoppageReason(result.data)
    } catch (error) {
      console.error("error getting stopages:", error);
    }
  };
  const bearingData =
    monitoringData?.sensorData?.map((sensor) => ({
      id: parseInt(sensor.deviceNo),
      label: sensor.bearingPosition,
      value: `Vib X:${sensor.velX}, Y:${sensor.velY}, Z:${sensor.velZ}, ACC:${sensor.acceleration}, Temp:${sensor.tempC}°C, Noise:${sensor.noiseDb}dB`,
    })) || [];

  const renderStartStopButtons = () => {
    if (monitoringData?.monitoringSession?.buttonStatus === "Completed") {
      return null;
    }

    return (
      <Stack direction="row" spacing={1}>
        <Button
          variant="contained"
          color="success"
          onClick={handleStart}
          disabled={isRunning}
          startIcon={<PlayArrow />}
          sx={{ minWidth: "100px" }}
        >
          START
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleStop}
          disabled={!isRunning}
          startIcon={<Stop />}
          sx={{ minWidth: "100px" }}
        >
          STOP
        </Button>
      </Stack>
    );
  };
  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        m: 2,
        borderRadius: 2,
        bgcolor: "#f8f9fa",
      }}
    >
      <Box
        sx={{
          p: 1,
          mb: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: "#fff",
          borderRadius: 1,
          boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Monitor sx={{ fontSize: 28, color: "#1976d2" }} />
          <Typography variant="h5" sx={{ fontWeight: 600, color: "#2c3e50" }}>
            Monitoring Page
          </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          p: 1,
          mb: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: "#fff",
          borderRadius: 1,
          boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
        }}
      >
        <Typography
          sx={{
            bgcolor: "#f1f8ff",
            p: 1,
            borderRadius: 1,
            fontSize: "20px",
            fontWeight: 500,
          }}
        >
          Gearbox Serial No: {gBoxSerialNo}
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{
            bgcolor: "#f1f8ff",
            p: 1,
            borderRadius: 1,
          }}
        >
          <Timer sx={{ color: "#1976d2" }} />
          <Typography variant="h6" sx={{ fontWeight: 600, color: "#2c3e50" }}>
            {formatTime(time)}
          </Typography>
          {renderStartStopButtons()}
        </Stack>
      </Box>

      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center", mt: 1 }}
        >
          <BearingDataTable bearingData={bearingData} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  p: 1,
                  bgcolor: "#fff",
                  borderRadius: 1,
                  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1, p: 1 }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#2b07c3", fontWeight: 600 }}
                  >
                    Oil Temp:
                  </Typography>
                  <Typography>
                    {monitoringData?.monitoringSession?.oilTemp || 0}°C
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  p: 1,
                  bgcolor: "#fff",
                  borderRadius: 1,
                  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                }}
              >
                {" "}
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1, p: 1 }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#2b07c3", fontWeight: 600 }}
                  >
                    Ambient Temp:
                  </Typography>
                  <Typography>
                    {monitoringData?.monitoringSession?.ambientTemp || 0}°C
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  p: 1,
                  bgcolor: "#fff",
                  borderRadius: 1,
                  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                }}
              >
                {" "}
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1, p: 1 }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#2b07c3", fontWeight: 600 }}
                  >
                    Temp Rise:
                  </Typography>
                  <Typography>
                    {monitoringData?.monitoringSession?.temperatureRise || 0}°C
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  p: 1,
                  bgcolor: "#fff",
                  borderRadius: 1,
                  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                }}
              >
                {" "}
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1, p: 1 }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#2b07c3", fontWeight: 600 }}
                  >
                    System Noise:
                  </Typography>
                  <Typography>
                    {monitoringData?.monitoringSession?.overallNoise || 0} dB
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: 1,
                  bgcolor: "#fff",
                  borderRadius: 1,
                  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1, p: 1 }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#2b07c3", fontWeight: 600 }}
                  >
                    Input Shaft RPM:
                  </Typography>
                  <Typography>
                    {monitoringData?.monitoringSession?.inputRpm || 0} RPM
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: 1,
                  bgcolor: "#fff",
                  borderRadius: 1,
                  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1, p: 1 }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#2b07c3", fontWeight: 600 }}
                  >
                    Output Shaft RPM:
                  </Typography>
                  <Typography>
                    {monitoringData?.monitoringSession?.outputRpm || 0} RPM
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {parametersList.map((param) => (
              <Grid item xs={12} md={6} key={param.id}>
                <Box
                  sx={{
                    p: 1,
                    bgcolor: "#fff",
                    borderRadius: 1,
                    boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      p: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "#2b07c3",
                        fontWeight: 600,
                        flexGrow: 1,
                      }}
                    >
                      {param.label}:
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        row
                        value={parameters[param.id]}
                        onChange={(e) => handleChange(e, param.id)}
                      >
                        <FormControlLabel
                          value="yes"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                "&.Mui-checked": {
                                  color: "#2b07c3",
                                },
                              }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                "&.Mui-checked": {
                                  color: "#2b07c3",
                                },
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box
            sx={{
              mt: 3,
              p: 2,
              bgcolor: "#fff",
              borderRadius: 1,
              boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: "#2b07c3",
                fontWeight: 600,
                mb: 1,
              }}
            >
              Remarks:
            </Typography>
            <TextField
              multiline
              rows={2}
              fullWidth
              placeholder="Enter your remarks here..."
              value={stopData.remarks}
              onChange={(e) => handleStopDataChange("remarks", e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#e0e0e0",
                  },
                  "&:hover fieldset": {
                    borderColor: "#2b07c3",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#2b07c3",
                  },
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              p: 2,
              bgcolor: "#fff",
              borderRadius: 1,
              boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={4}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 600, color: "#f57c00" }}
                  >
                    System Alert:
                  </Typography>
                  <Typography
                    sx={{
                      color: monitoringData?.monitoringSession?.colorIndicator === "GREEN" ? "#4caf50" : "#f44336",
                      fontWeight: 500,
                    }}
                  >
                    Current status:{" "}
                    {monitoringData?.monitoringSession?.status || ""}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Grid item xs={12}>
            <Box
              sx={{
                p: 2,
                bgcolor: "#fff",
                borderRadius: 1,
                boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
              }}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={8}>
                <FormControl fullWidth>
              <InputLabel>Stop Reason</InputLabel>
              <Select
                value={stopData.reason}
                label="Stop Reason"
                onChange={(e) => handleStopDataChange("reason", e.target.value)}
              >
                {stoppageReason.map((reason) => (
                  <MenuItem key={reason.id} value={reason.reason}>
                    {reason.reason}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      height: "56px",
                      textTransform: "none",
                      fontSize: "1.1rem",
                      fontWeight: 600,
                      color: "white",
                      boxShadow: "0 4px 6px rgba(25, 118, 210, 0.25)",
                      "&:hover": {
                        boxShadow: "0 6px 8px rgba(25, 118, 210, 0.35)",
                      },
                    }}
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default PTPLMonitoring;
