import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  styled,
  tableCellClasses,
  TablePagination,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { operatorApi } from "../../api/PTPL API/operatorApi";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function PTPLOperatorMaster() {
  const [operators, setOperators] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success"
  });

  const [operatorData, setOperatorData] = useState({
    operatorName: "",
    employee: "",
  });

  useEffect(() => {
    fetchOperators();
  }, []);

  const fetchOperators = async () => {
    try {
      const response = await operatorApi.getoperator();
      setOperators(response.data);
      console.log(response.data)
      setLoading(false);
    } catch (error) {
      console.error("Error fetching operators:", error);
      showSnackbar("Failed to fetch operators", "error");
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOperatorData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddSubmit = async () => {
    try {
      await operatorApi.addoperator(operatorData);
      setAddOpen(false);
      fetchOperators();
      showSnackbar("Operator added successfully", "success");
      setOperatorData({ operatorName: "", employee: "" });
    } catch (error) {
      showSnackbar("Failed to add operator", "error");
    }
  };

  const handleEditClick = (operator) => {
    setSelectedOperator(operator);
    setOperatorData({
      operatorName: operator.operatorName,
      employee: operator.employee,
    });
    setEditOpen(true);
  };

  const handleEditSubmit = async () => {
    try {
      await operatorApi.updateoperator(selectedOperator.id, operatorData);
      setEditOpen(false);
      fetchOperators();
      showSnackbar("Operator updated successfully", "success");
    } catch (error) {
      showSnackbar("Failed to update operator", "error");
    }
  };

  const handleDeleteClick = (operator) => {
    setSelectedOperator(operator);
    setDeleteOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await operatorApi.deleteoperator(selectedOperator.id);
      setDeleteOpen(false);
      fetchOperators();
      showSnackbar("Operator deleted successfully", "success");
    } catch (error) {
      showSnackbar("Failed to delete operator", "error");
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{ padding: "0px 20px" }}>
      <div style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: "5px",
        paddingBottom: "5px",
      }}>
        <h2>Operator Master</h2>
        <Button
          onClick={() => setAddOpen(true)}
          style={{
            fontWeight: "500",
            borderRadius: "4px",
            color: "gray",
            border: "2px solid gray",
            padding: "5px",
            marginTop: "5px",
          }}
        >
          Add New &nbsp;
          <FontAwesomeIcon style={{ fontSize: "18px", color: "gray" }} icon={faPlus} />
        </Button>
      </div>

      <Box>
        <Table size="small" style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Operator Name</StyledTableCell>
              <StyledTableCell>Employee ID</StyledTableCell>
              <StyledTableCell>Created By</StyledTableCell>

              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              Array.from(Array(5).keys()).map((index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell><Skeleton animation="wave" /></StyledTableCell>
                  <StyledTableCell><Skeleton animation="wave" /></StyledTableCell>
                  <StyledTableCell><Skeleton animation="wave" /></StyledTableCell>
                  <StyledTableCell><Skeleton animation="wave" /></StyledTableCell>
                  <StyledTableCell><Skeleton animation="wave" /></StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              operators
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((operator) => (
                  <StyledTableRow key={operator.id}>
                    <StyledTableCell>{operator.operatorName}</StyledTableCell>
                    <StyledTableCell>{operator.employeeId}</StyledTableCell>
                    <StyledTableCell>{operator.createdBy}</StyledTableCell>

                    <StyledTableCell>{new Date(operator.createdAt).toLocaleDateString()}</StyledTableCell>
                    <StyledTableCell style={{ display: "flex", gap: "10px" }}>
                      <IconButton onClick={() => handleEditClick(operator)}>
                        <EditIcon />
                      </IconButton>
                      <div className="divider" style={{ height: "20px", width: "2px", backgroundColor: "#0003" }}></div>
                      <IconButton style={{ color: "#FF3131" }} onClick={() => handleDeleteClick(operator)}>
                        <DeleteIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
            )}
          </TableBody>
        </Table>
        
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={operators.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Modal open={addOpen} onClose={() => setAddOpen(false)}>
          <div style={{
            borderRadius: "10px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            minWidth: "500px",
          }}>
            <button
              onClick={() => setAddOpen(false)}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "30px",
              }}
            >
              &times;
            </button>
            <h2>Add New Operator</h2>
            <hr />
            <br />
            <div>
              <TextField
                label="Employee ID"
                name="employee"
                value={operatorData.employee}
                onChange={handleInputChange}
                style={{ width: "14rem", marginBottom: "10px" }}
              />
              <TextField
                label="Operator Name"
                name="operatorName"
                value={operatorData.operatorName}
                onChange={handleInputChange}
                style={{ marginLeft: "20px", width: "14rem", marginBottom: "10px" }}
              />
            </div>
            <Button
              onClick={handleAddSubmit}
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
            >
              Add
            </Button>
          </div>
        </Modal>

        <Modal open={editOpen} onClose={() => setEditOpen(false)}>
          <div style={{
            borderRadius: "10px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            minWidth: "500px",
          }}>
            <button
              onClick={() => setEditOpen(false)}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "30px",
              }}
            >
              &times;
            </button>
            <h2>Update Operator</h2>
            <hr />
            <br />
            <div>
              <TextField
                label="Employee ID"
                name="employee"
                value={operatorData.employee}
                onChange={handleInputChange}
                style={{ width: "14rem", marginBottom: "10px" }}
              />
              <TextField
                label="Operator Name"
                name="operatorName"
                value={operatorData.operatorName}
                onChange={handleInputChange}
                style={{ marginLeft: "20px", width: "14rem", marginBottom: "10px" }}
              />
            </div>
            <Button
              onClick={handleEditSubmit}
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
            >
              Update
            </Button>
          </div>
        </Modal>

        <Dialog
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete operator "{selectedOperator?.operatorName}"?
              This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="error" variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </MuiAlert>
        </Snackbar>
      </Box>
    </div>
  );
}