import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@mui/material";

const PartForm = ({ partData, onInputChange, onSubmit, submitButtonText }) => {
  return (
    <>
      <div>
        <FormControl sx={{ marginBottom: "10px", width: "26ch",color:'black' }}>
          <InputLabel>Series</InputLabel>
          <Select
            name="series"
            value={partData.series}
            onChange={onInputChange} style={{}}
          >
            <MenuItem value="H">H</MenuItem>
            <MenuItem value="B">B</MenuItem>
            <MenuItem value="V">V</MenuItem>
            <MenuItem value="P">P</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Order Number"
          value={partData.orderNumber}
          name="orderNumber"
          onChange={onInputChange}
          style={{
            marginLeft: "20px",
            width: "14rem",
            marginBottom: "10px",
          }}
        />
      </div>

      <div>
        <FormControl sx={{ marginBottom: "10px", width: "26ch" }}>
          <InputLabel>Model</InputLabel>
          <Select name="model" value={partData.model} onChange={onInputChange}>
            <MenuItem value="H1">H1</MenuItem>
            <MenuItem value="B1">B1</MenuItem>
            <MenuItem value="V1">V1</MenuItem>
            <MenuItem value="P1">P1</MenuItem>
          </Select>
        </FormControl>
        <TextField
          name="testingTime"
          value={partData.testingTime}
          onChange={onInputChange}
          label="Testing Time"
          style={{ width: "14rem", marginBottom: "10px", marginLeft: "20px" }}
        />
      </div>

      <div>
        <FormControl sx={{ marginBottom: "10px", width: "26ch" }}>
          <InputLabel>Type</InputLabel>
          <Select name="type" value={partData.type} onChange={onInputChange}>
            <MenuItem value="Helical">Helical</MenuItem>
            <MenuItem value="Vertical">Vertical</MenuItem>
            <MenuItem value="Circular">Circular</MenuItem>
          </Select>
        </FormControl>
        <TextField
          name="gaDrawingNumber"
          value={partData.gaDrawingNumber}
          onChange={onInputChange}
          label="GA Drawing No."
          style={{ width: "14rem", marginBottom: "10px", marginLeft: "20px" }}
        />
      </div>
      <div>
        <TextField
          name="customerName"
          value={partData.customerName}
          onChange={onInputChange}
          label="Customer Name"
          style={{ width: "26ch", marginBottom: "10px" }}
        />
        <TextField
          name="gearBoxNumber"
          value={partData.gearBoxNumber}
          onChange={onInputChange}
          label="Gear Box Sr. No."
          style={{ width: "14rem", marginBottom: "10px", marginLeft: "20px" }}
        />
      </div>
      <div>
        <TextField
          name="gearboxDescription"
          value={partData.gearboxDescription}
          onChange={onInputChange}
          label="Gear Box Description"
          style={{ width: "26ch", marginBottom: "10px" }}
        />
        <TextField
          name="ratio"
          value={partData.ratio}
          onChange={onInputChange}
          label="Ratio"
          style={{ width: "14rem", marginBottom: "10px", marginLeft: "20px" }}
        />
      </div>

      <div>
        <TextField
          name="requiredInputSpeed"
          value={partData.requiredInputSpeed}
          onChange={onInputChange}
          label="Required Input Speed"
          style={{ width: "26ch", marginBottom: "10px" }}
        />
        <TextField
          name="flsPumpSerialNumber"
          value={partData.flsPumpSerialNumber}
          onChange={onInputChange}
          label="FLS/Pump Sr. No."
          style={{ width: "14rem", marginBottom: "10px", marginLeft: "20px" }}
        />
      </div>
      <div>
        <TextField
          name="capacity"
          value={partData.capacity}
          onChange={onInputChange}
          label="Capacity"
          style={{ width: "26ch", marginBottom: "10px" }}
        />
          <TextField
          name="flsPumpDetailsMake"
          value={partData.flsPumpDetailsMake}
          onChange={onInputChange}
          label="FLS/Pump Details"
          style={{ width: "14rem", marginBottom: "10px", marginLeft: "20px" }}
        />
      </div>
      <Button
        onClick={onSubmit}
        variant="contained"
        color="primary"
        style={{ marginTop: "20px" }}
      >
        {submitButtonText}
      </Button>
    </>
  );
};

export default PartForm;
