import React from 'react';
import { Modal } from "@mui/material";

const CustomModal = ({ open, onClose, title, children }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div style={{
        borderRadius: "10px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: "20px",
        minWidth: "500px",
      }}>
        <button
          onClick={onClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
            backgroundColor: "transparent",
            border: "none",
            fontSize: "30px",
          }}
        >
          &times;
        </button>
        <h2>{title}</h2>
        <hr />
        <br />
        {children}
      </div>
    </Modal>
  );
};

export default CustomModal;
