import axios from "axios";
import { baseUrl, company_id } from "./baseUrl";

export const apiLogin = async (username, password) => {
  const url = `${baseUrl}/auth/signin`;
  console.log("company id, baseurl", company_id, baseUrl);
  
  try {
    const data = await axios.post(url, {
      // companyId: company_id,
      userId: username,
      password: password,
    });
    return data;
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};