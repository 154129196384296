// import React from 'react';
// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// const LineChart = ({ data, labels, title, xAxisLabel, yAxisLabel, defaultColor = 'white', }) => {
//   const chartData = {
//     labels: labels,
//     datasets: data.map((dataset, index) => ({
//       label: dataset.name,
//       data: dataset.data,
//       fill: false,
//       borderColor: getColor(dataset.name, index, defaultColor),
//       tension: 0.1
//     }))
//   };

//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     plugins: {
//       legend: {
//         position: 'top',
//         labels: {
//           color: 'white',
//           font: {
//             size: 12
//           }
//         }
//       },
//       title: {
//         display: true,
//         text: title,
//         font: {
//           size: 16
//         },
//         color: 'white'
//       },
//     },
//     scales: {
//       x: {
//         title: {
//           display: true,
//           text: xAxisLabel,
//           color: 'white',
//         },
//         grid: {
//           color: 'black'
//         },
//         ticks: {
//           color: 'white'
//         }
//       },
//       y: {
//         title: {
//           display: true,
//           text: yAxisLabel,
//           color: 'white',
//         },
//         grid: {
//           color: 'black'
//         },
//         ticks: {
//           color: 'white'
//         },
//       }
//     }
//   };

//   function getColor(name, index, defaultColor) {
//     if (name === 'R') return 'red';
//     if (name === 'Y') return 'yellow';
//     if (name === 'B') return 'blue';
//     if (name === 'Import') return '#efe4b0';
//     if (name === 'Export') return '#b97a58';
//     if (name === 'Loss') return '#ff6b6b';
//     if (name === 'THD Current') return '#d4fefb';
//     return defaultColor || `hsl(${index * 137.5},70%,50%)`;
//   }

//   return (

//       <Line data={chartData} options={options} />
  
//   );
// };

// export default LineChart;




import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChart = ({ data, labels, title, xAxisLabel, yAxisLabel, defaultColor = 'white' }) => {

  const getLineStyle = (name, index) => {
    const styles = {
      R: {
        borderColor: 'red',
        borderWidth: 3,
        pointStyle: 'circle',
        pointRadius: 5,
        pointBorderWidth: 2,
        borderDash: [],
      },
      Y: {
        borderColor: 'yellow',
        borderWidth: 5,
        pointStyle: 'crossRot',
        pointRadius: 6,
        // pointBorderWidth: 3,
        borderDash: [5, 5],
      },
      B: {
        borderColor: 'blue',
        borderWidth: 8,
        pointStyle: 'star',
        pointRadius: 6,
        // pointBorderWidth: 4,
        borderDash: [2, 2],
      },
      Import: {
        borderColor: '#efe4b0',
        borderWidth: 2,
        pointStyle: 'star',
        pointRadius: 6,
        borderDash: [],
      },
      Export: {
        borderColor: '#b97a58',
        borderWidth: 3,
        pointStyle: 'crossRot',
        pointRadius: 6,
        borderDash: [5, 5],
      },
      Lag: {
        borderColor: '#e706e3',
        borderWidth: 2,
        pointStyle: 'star',
        pointRadius: 6,
        borderDash: [],
      },
      Lead: {
        borderColor: '#f937b7',
        borderWidth: 3,
        pointStyle: 'crossRot',
        pointRadius: 6,
        borderDash: [5, 5],
      }
    };

    return styles[name] || {
      borderColor: defaultColor || `hsl(${index * 137.5},70%,50%)`,
      borderWidth: 2,
      pointStyle: 'circle',
      pointRadius: 4,
      borderDash: [],
    };
  };

  const chartData = {
    labels: labels,
    datasets: data.map((dataset, index) => ({
      label: dataset.name,
      data: dataset.data,
      fill: false,
      tension: 0.1,
      ...getLineStyle(dataset.name, index)
    }))
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: 'white',
          font: { size: 12 },
          usePointStyle: true,
          pointStyle: 'line',
          padding: 20,
        }
      },
      title: {
        display: true,
        text: title,
        font: { size: 16 },
        color: 'white',
        padding: { bottom: 20 }
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        padding: 10,
        titleColor: 'white',
        bodyColor: 'white',
        borderColor: 'white',
        borderWidth: 1,
        displayColors: true,
        intersect: false,
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: xAxisLabel,
          color: 'white',
          padding: { top: 10 }
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
          drawBorder: false,
        },
        ticks: {
          color: 'white',
          padding: 5,
        }
      },
      y: {
        title: {
          display: true,
          text: yAxisLabel,
          color: 'white',
          padding: { bottom: 10 }
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
          drawBorder: false,
        },
        ticks: {
          color: 'white',
          padding: 5,
        },
      }
    },
    elements: {
      point: {
        hoverRadius: 8,
        hoverBorderWidth: 2,
      },
      line: {
        borderWidth: 2,
      }
    }
  };

  return <Line data={chartData} options={options} />;
};

export default LineChart;