import React, { useEffect, useState } from "react";
import { Box, IconButton, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { partsApi } from "../../api/PTPL API/partsApi";
import CustomTable from "./Table/CustomTable";
import CustomModal from "./Modals/CustomModal";
import PartForm from "./Forms/PartForm";
import DeleteConfirmationModal from "./Modals/DeleteConfirmationModal";

const initialPartData = {
  
  capacity:"",
  flsPumpSerialNumber:"",
  flsPumpDetailsMake:"",
  requiredInputSpeed:"",
  ratio:"",
  gearboxDescription:"",
  gearBoxNumber:"",
  customerName:"",
  orderNumber:"",
  gaDrawingNumber:"",
  type:"",
  testingTime:"4",
  model:"",
  series:""

};

const columns = [
  { id: 'series', label: 'Series' },
  { id: 'orderNumber', label: 'Order Number' },
  { id: 'model', label: 'Model' },
  { id: 'testingTime', label: 'Testing Time' },
  { id: 'type', label: 'Type' },
  { id: 'gaDrawingNumber', label: 'GA Drawing No.' },
  {id:'customerName',label: 'Customer Name'},
  {id:'gearBoxNumber',label: 'Gear Box Sr. No.'},
  {id:'gearboxDescription',label: 'Gear Box Description'},
  {id:'ratio',label: 'Ratio'},
  {id:'flsPumpDetailsMake',label: 'FLS/Pump Details'},
  {id:'flsPumpSerialNumber',label: 'FLS/Pump Serial Number'},
  {id:'capacity',label: 'Capacity'},
 
];

export default function PTPLPartMaster() {
  const [partData, setPartData] = useState(initialPartData);
  const [parts, setParts] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedPart, setSelectedPart] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchParts();
  }, []);

  const fetchParts = async () => {
    try {
      setLoading(true);
      const result = await partsApi.getParts();
      console.log('Fetched parts:', result.data);
      setParts(result.data);
    } catch (error) {
      showSnackbar("Error fetching parts", "error");
      console.error("Error fetching parts:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPartData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddSubmit = async () => {
    try {
      await partsApi.addParts(partData);
      showSnackbar("Part added successfully", "success");
      setAddOpen(false);
      setPartData(initialPartData);
      fetchParts();
    } catch (error) {
      showSnackbar("Error adding part", "error");
      console.error("Error adding part:", error);
    }
  };

  const handleEditClick = (part) => {
    setSelectedPart(part);
    setPartData({
      capacity:part.capacity,
      flsPumpSerialNumber:part.flsPumpSerialNumber,
      flsPumpDetailsMake:part.flsPumpDetailsMake,
      requiredInputSpeed:part.requiredInputSpeed,
      ratio:part.ratio,
      gearboxDescription:part.gearboxDescription,
      gearBoxNumber:part.gearBoxNumber,
      customerName:part.customerName,
      orderNumber:part.orderNumber,
      gaDrawingNumber:part.gaDrawingNumber,
      type:part.type,
      testingTime:part.testingTime,
      model:part.model,
      series:part.series

    });
    setEditOpen(true);
  };

  const handleEditSubmit = async () => {
    try {
      if (!selectedPart?.id) {
        throw new Error("No part selected for update");
      }
      
      // Now passing both id and updated data
      await partsApi.updateParts(selectedPart.id, partData);
      
      showSnackbar("Part updated successfully", "success");
      setEditOpen(false);
      setPartData(initialPartData);
      setSelectedPart(null);
      fetchParts();
    } catch (error) {
      showSnackbar("Error updating part", "error");
      console.error("Error updating part:", error);
    }
  };

  const handleDeleteClick = (part) => {
    setSelectedPart(part);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      if (!selectedPart?.id) {
        throw new Error("No customer selected for deletion");
      }
      
      await partsApi.deleteParts(selectedPart.id);
      showSnackbar("Customer deleted successfully", "success");
      setDeleteModalOpen(false);
      setSelectedPart(null);
      fetchParts();
    } catch (error) {
      showSnackbar("Error deleting Customer", "error");
      console.error("Error deleting part:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleModalClose = () => {
    setAddOpen(false);
    setEditOpen(false);
    setPartData(initialPartData);
    setSelectedPart(null);
  };

  const renderActions = (row) => (
    <div style={{ display: "flex", gap: "10px" }}>
      <IconButton onClick={() => handleEditClick(row)}>
        <EditIcon />
      </IconButton>
      <IconButton
        style={{ color: "#FF3131" }}
        onClick={() => handleDeleteClick(row)}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );

  return (
    <div style={{ padding: "0px 20px" }}>
      <div style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: "5px",
        paddingBottom: "5px",
      }}>
        <h2>Customer Master</h2>
        <div>
        <Button
          onClick={() => setAddOpen(true)}
          style={{
            fontWeight: "500",
            borderRadius: "4px",
            color: "gray",
            border: "2px solid gray",
            padding: "5px",
            marginTop: "5px",
            marginRight:'5px'
          }}
        >
          Add New &nbsp;
          <FontAwesomeIcon style={{ fontSize: "18px", color: "gray" }} icon={faPlus} />
        </Button>
        <Button
          // onClick={() => setAddOpen(true)}
          style={{
            fontWeight: "500",
            borderRadius: "4px",
            color: "gray",
            border: "2px solid gray",
            padding: "5px",
            marginTop: "5px",
          }}
        >
          Upload Excel &nbsp;
          <FontAwesomeIcon style={{ fontSize: "18px", color: "gray" }} icon={faUpload} />
        </Button>
        </div>
      </div>

      <Box sx={{maxWidth:'80hv',overflowX:'auto'}}>
        <CustomTable
          columns={columns}
          data={parts}
          loading={loading}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          renderActions={renderActions}
        />

        <CustomModal
          open={addOpen}
          onClose={handleModalClose}
          title="Add New Customer"
        >
          <PartForm
            partData={partData}
            onInputChange={handleInputChange}
            onSubmit={handleAddSubmit}
            submitButtonText="Add"
          />
        </CustomModal>

        <CustomModal
          open={editOpen}
          onClose={handleModalClose}
          title="Edit Customer Details"
        >
          <PartForm
            partData={partData}
            onInputChange={handleInputChange}
            onSubmit={handleEditSubmit}
            submitButtonText="Update"
          />
        </CustomModal>

        <DeleteConfirmationModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleConfirmDelete}
        />

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
        >
          <MuiAlert
            onClose={() => setOpenSnackbar(false)}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </Box>
    </div>
  );
}