import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

const BearingDataTable = ({ bearingData }) => {
  const columns = [
    { id: 'bearing', label: 'Bearing' },
    { id: 'velX', label: 'Vel X (mm/sec)' },
    { id: 'velY', label: 'Vel Y (mm/sec)' },
    { id: 'velZ', label: 'Vel Z (mm/sec)' },
    { id: 'acceleration', label: 'Acceleration (mm/sec²)' },
    { id: 'temp', label: 'Temp (°C)' },
    // { id: 'noise', label: 'Noise (db)' },
  ];

  
  const bearingMap = bearingData.reduce((acc, bearing) => {
    acc[bearing.label] = bearing;
    return acc;
  }, {});
  const filteredBearings = Object.keys(bearingMap).filter(label => 
    bearingMap[label].value && bearingMap[label].value.trim() !== ''
  );

  return (
    <TableContainer
      component={Paper}
      elevation={3}
      sx={{
        borderRadius: 2,
        maxWidth: '100%',
        overflowX: 'auto'
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                sx={{
                  bgcolor: 'rgb(31, 174, 197)',
                  color: '#fff',
                  fontWeight: 600,
                  padding: '12px 16px',
                  whiteSpace: 'nowrap',
                  textAlign: column.id === 'bearing' ? 'left' : 'center',
                  fontSize:'18px'
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredBearings.map((bearingLabel, index) => {
            const bearingData = bearingMap[bearingLabel];
            
            return (
              <TableRow
                key={bearingLabel}
                sx={{
                  '&:nth-of-type(odd)': {
                    bgcolor: '#f8f9fa',
                  },
                }}
              >
                <TableCell
                  sx={{
                    fontWeight: 600,
                    padding: '12px 16px',
                    bgcolor: 'rgb(225, 225, 225)',
                    fontSize:'16px'
                  }}
                >
                  {bearingLabel}
                </TableCell>
                <TableCell sx={{ fontWeight: 600,textAlign: 'center', padding: '12px 16px',fontSize:'18px'  }}>
                  {bearingData.value?.includes('Vib X:') 
                    ? bearingData.value.split('Vib X:')[1].split(',')[0].trim() 
                    : '--'}
                </TableCell>
                <TableCell sx={{fontWeight: 600, textAlign: 'center', padding: '12px 16px',fontSize:'18px' }}>
                  {bearingData.value?.includes('Y:') 
                    ? bearingData.value.split('Y:')[1].split(',')[0].trim() 
                    : '--'}
                </TableCell>
                <TableCell sx={{ fontWeight: 600,textAlign: 'center', padding: '12px 16px',fontSize:'18px'  }}>
                  {bearingData.value?.includes('Z:') 
                    ? bearingData.value.split('Z:')[1].split(',')[0].trim() 
                    : '--'}
                </TableCell>
                <TableCell sx={{ fontWeight: 600,textAlign: 'center', padding: '12px 16px',fontSize:'18px'  }}>
                  {bearingData.value?.includes('ACC:') 
                    ? bearingData.value.split('ACC:')[1].split(',')[0].trim() 
                    : '--'}
                </TableCell>
                <TableCell sx={{fontWeight: 600, textAlign: 'center', padding: '12px 16px',fontSize:'18px'  }}>
                  {bearingData.value?.includes('Temp:') 
                    ? bearingData.value.split('Temp:')[1].split('°C')[0].trim() 
                    : '--'}
                </TableCell>
                {/* <TableCell sx={{fontWeight: 600, textAlign: 'center', padding: '12px 16px',fontSize:'18px'  }}>
                  {bearingData.value?.includes('Noise:') 
                    ? bearingData.value.split('Noise:')[1].split('dB')[0].trim() 
                    : '--'}
                </TableCell> */}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BearingDataTable;