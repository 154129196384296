// // import { Router } from '@mui/icons-material'
// import React from "react";
// import { HashRouter as Router, Route, Routes } from "react-router-dom";
// import CBM from "../components/cbm";
// import Operations from "../components/operations";
// import Sidebar from "../components/sidebar";
// import PartMaster from "../components/partmaster";
// // import SignInSide from '../components/Login'
// import Login from "../components/Login";
// import PathrediOpe from "../components/pathrediope";
// import GhaziabadOpe from "../components/ghaziabadope";
// import UATRawData from "../components/UATRawData";
// import UATReport from "../components/UATReport";
// import UATTesting from "../components/UATTesting";
// import FacilityManagement from "../components/facilitymanagement";
// import Rawghaziabad from "../components/Rawghaziabad";
// import Rawpathrediope from "../components/Rawpathrediope copy";
// import DeviceMaster from "../components/devicemaster";
// import MachineMaster from "../components/machinemaster";
// import LineMaster from "../components/linemaster";
// import PlantMaster from "../components/plantmaster";
// import FrdcMaster from "../components/frdcmaster";
// import Temp from "../components/temp";
// import MachineDownTime from "../components/machinedowntime";
// import QualityRejection from "../components/qualityrejection";
// import ShiftMaster from "../components/shiftmaster";
// import GhaziabadOpe2 from "../components/ghaziabadope2";
// import PathrediOpe2 from "../components/pathrediope2";
// import MachineInput from "../components/machineinput";
// import ChangeOverMaster from "../components/changeovermaster";
// import FacilitySustainability from "../components/facilitysustainability";
// import HourlyBucketM1 from "../components/hourlybucket_m1";
// import HourlyBucketM2 from "../components/hourlybucket_m2";
// import EnergyManagement from "../components/energymanagement";
// import BasicGauges from "../components/chartssize";
// import AirQualityManagement from "../components/airqualitymanagement";
// import DieselGenset from "../components/dieselgenset";
// import STPManagement from "../components/stpmanagement";
// import EnlargedChartPage from "../components/fullviewchart";
// import WaterManagement from "../components/watermanagement";
// import Welcome from "../components/welcome";
// import DailyReportM1 from "../components/dailyreportm1";
// import DailyReportM2 from "../components/dailyreportm2";
// import WeeklyReportM1 from "../components/weeklyreportm1";
// import WeeklyReportM2 from "../components/weeklyreportm2";
// import MonthlyReportM1 from "../components/monthlyreportm1";
// import MonthlyReportM2 from "../components/monthlyreportm2";
// import QuaterlyReportM1 from "../components/quaterlyreportm1";
// import QuaterlyReportM2 from "../components/quaterlyreportm2";
// import YearlyReportM1 from "../components/yearlyreportm1";
// import YearlyReportM2 from "../components/yearlyreportm2";
// import UnknownLoss from "../components/unknownloss";
// import COntrolRoom from "../components/controlroom";
// import WholeDayReportM1 from "../components/wholedayreportm1";
// import WholeDayReportM2 from "../components/wholedayreportm2";
// import ChartComponent from "../components/charts";
// import Dashboard from "../components/dashboard";
// import Publisher from "../components/publishers";
// import Tbm from "../components/tbm";
// import Thresholds from "../components/thresholds";
// import ADMReports from "../components/Reports";
// import SmartDashboard from "../components/SmartDashboard";
// import EMSDashboard from "../components/EMS/EMSDashboard";
// import RawData from "../components/EMS/RawData";
// import CBMDetail from "../components/Scoul/cbmDetails";
// import IconicDashboard from "../components/Scoul/CockPitView";
// import VibrationGraphs from "../components/Scoul/CBMGraphs";
// import PowerGraphs from "../components/Scoul/EnergyGraphs";
// import ADMRawData from "../components/ADMRawData";
// import SoftwareReportTable from "../components/PTPL/DemoReports";
// import PTPLPartMaster from "../components/PTPL/PartMaster";
// import PTPLSettings from "../components/PTPL/Settings";
// import PTPLMonitoring from "../components/PTPL/Monitoring";
// import PTPLTestingReport from "../components/PTPL/TestingReport";
// import PTPLCustomerMaster from "../components/PTPL/CustomerMaster";
// import PTPLOperatorMaster from "../components/PTPL/OperatorMaster";

// export default function MyRoutes() {
//   return (
//     <Routes>
//       <Route path="/cbm" element={<CBM />} />
//       <Route path="/operations" element={<Operations />} />
//       <Route path="/sidebar" element={<Sidebar />} />
//       <Route path="/login" element={<Login />} />
//       <Route path="" element={<Login />} />
//       <Route path="/pathredi_ope" element={<PathrediOpe />} />
//       <Route path="/uat/uat_rawdata" element={<UATRawData />} />
//       <Route path="/uat/uat_reports" element={<UATReport />} />
//       <Route path="/uat/uat_testing" element={<UATTesting />} />
//       <Route path="/rawdata" element={<RawData />} />
//       <Route path="/hour_bucket_m1" element={<HourlyBucketM1 />} />
//       <Route path="/hour_bucket_m2" element={<HourlyBucketM2 />} />
//       <Route path="/raw_data/uat_ghaziabad" element={<Rawghaziabad />} />
//       <Route path="/ghaziabad_ope" element={<GhaziabadOpe />} />
//       <Route path="/scoul/cbmdetail" element={<CBMDetail />} />
//       <Route path="/facilitymanagement" element={<FacilityManagement />} />
//       <Route
//         path="/plant_architecture/plant_master"
//         element={<PlantMaster />}
//       />
//       <Route path="/plant_architecture/linemaster" element={<LineMaster />} />
//       <Route
//         path="/plant_architecture/devicemaster"
//         element={<DeviceMaster />}
//       />
//       <Route path="/publishers" element={<Publisher />} />
//       <Route path="/tbm" element={<Tbm />} />
//       <Route path="/admreports" element={<ADMReports />} />
//       <Route path="/smart_dashboard" element={<SmartDashboard />} />
//       <Route path="/scoul/cockpitview" element={<IconicDashboard />} />
//       <Route path="/scoul/powergraphs" element={<PowerGraphs />} />
//       <Route path="/scoul/cbmgraphs" element={<VibrationGraphs />} />
//       <Route path="plant_architecture/thresholds" element={<Thresholds />} />
//       <Route path="/plant_architecture/part_master" element={<PartMaster />} />
//       <Route
//         path="/production_plan/machine_downtime"
//         element={<MachineDownTime />}
//       />
//       <Route
//         path="/production_plan/quality_rejection"
//         element={<QualityRejection />}
//       />
//       <Route path="/production_plan/unknown_loss" element={<UnknownLoss />} />
//       <Route
//         path="/device_status"
//         element={<COntrolRoom />}
//       />
//       <Route path="/production_plan/shift_master" element={<ShiftMaster />} />
//       <Route path="/dashboard" element={<Dashboard />} />
//       <Route path="/method2/machine_input" element={<MachineInput />} />
//       <Route path="/method2/ghaziabad_ope_2" element={<GhaziabadOpe2 />} />
//       <Route path="/method2/pathredi_ope_2" element={<PathrediOpe2 />} />
//       <Route path="/method1/pathredi_ope_1" element={<PathrediOpe />} />
//       <Route path="/method1/ghaziabad_ope_1" element={<GhaziabadOpe />} />
//       <Route
//         path="/plant_architecture/frdc_master"
//         element={<ChangeOverMaster />}
//       />
//       <Route
//         path="/facilitysustainability"
//         element={<FacilitySustainability />}
//       />
//       <Route path="/energymanagement" element={<EnergyManagement />} />
//       <Route path="/airquality" element={<AirQualityManagement />} />
//       <Route path="/dieselgenset" element={<DieselGenset />} />
//       <Route path="/common/emsdashboard" element={<EMSDashboard />} />
//       <Route path="/common/rawdata" element={<RawData />} />
//       <Route path="/charts" element={<ChartComponent />} />
//       <Route path="/temp" element={<Temp />} />
//       <Route path="/basic" element={<BasicGauges />} />
//       <Route path="/stpmanagement" element={<STPManagement />} />
//       <Route path="/fullviewchart" element={<EnlargedChartPage />} />
//       <Route path="/watermanagement" element={<WaterManagement />} />
//       <Route path="/welcome" element={<Welcome />} />
//       <Route path="/reportm1/daily" element={<DailyReportM1 />} />
//       <Route path="/reportm1/hour_bucket_m1" element={<HourlyBucketM1 />} />
//       <Route path="/reportm2/hour_bucket_m2" element={<HourlyBucketM2 />} />
//       <Route path="/reportm2/daily" element={<DailyReportM2 />} />
//       <Route path="/reportm1/wholedayreportm1" element={<WholeDayReportM1 />} />
//       <Route path="/reportm2/wholedayreportm2" element={<WholeDayReportM2 />} />
//       <Route path="/reportm1/weekly" element={<WeeklyReportM1 />} />
//       <Route path="/reportm2/weekly" element={<WeeklyReportM2 />} />{" "}
//       <Route path="/reportm1/monthly" element={<MonthlyReportM1 />} />
//       <Route path="/reportm2/monthly" element={<MonthlyReportM2 />} />
//       <Route path="/reportm1/quaterly" element={<QuaterlyReportM1 />} />
//       <Route path="/reportm2/quaterly" element={<QuaterlyReportM2 />} />
//       <Route path="/reportm1/yearly" element={<YearlyReportM1 />} />
//       <Route path="/reportm2/yearly" element={<YearlyReportM2 />} />
//       <Route path="/adm/rawdata" element={<ADMRawData />} />
//       <Route path="/ptpl/ptplreports" element={<SoftwareReportTable />} />
//       <Route path="/ptpl/partmaster" element={<PTPLPartMaster />} />
//       <Route path="/ptpl/ptplsettings" element={<PTPLSettings />} />
//       <Route path="/ptpl/ptplmonitoring" element={<PTPLMonitoring />} />
//       <Route path="/ptpl/ptpltesting" element={<PTPLTestingReport />} />
//       <Route path="/ptpl/customermaster" element={<PTPLCustomerMaster />} />
//       <Route path="/ptpl/operatormaster" element={<PTPLOperatorMaster />} />
//       <Route path="/ptpl/addparts" element={<PTPLPartMaster />} />
//       <Route path="/plant_architecture/machine_master"  element={<MachineMaster />}/>
//     </Routes>
//   );
// }

import React, { useEffect } from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import CBM from "../components/cbm";
import Operations from "../components/operations";
import Sidebar from "../components/sidebar";
import PartMaster from "../components/partmaster";
// import SignInSide from '../components/Login'
import Login from "../components/Login";
import PathrediOpe from "../components/pathrediope";
import GhaziabadOpe from "../components/ghaziabadope";
import UATRawData from "../components/UATRawData";
import UATReport from "../components/UATReport";
import UATTesting from "../components/UATTesting";
import FacilityManagement from "../components/facilitymanagement";
import Rawghaziabad from "../components/Rawghaziabad";
import Rawpathrediope from "../components/Rawpathrediope copy";
import DeviceMaster from "../components/devicemaster";
import MachineMaster from "../components/machinemaster";
import LineMaster from "../components/linemaster";
import PlantMaster from "../components/plantmaster";
import FrdcMaster from "../components/frdcmaster";
import Temp from "../components/temp";
import MachineDownTime from "../components/machinedowntime";
import QualityRejection from "../components/qualityrejection";
import ShiftMaster from "../components/shiftmaster";
import GhaziabadOpe2 from "../components/ghaziabadope2";
import PathrediOpe2 from "../components/pathrediope2";
import MachineInput from "../components/machineinput";
import ChangeOverMaster from "../components/changeovermaster";
import FacilitySustainability from "../components/facilitysustainability";
import HourlyBucketM1 from "../components/hourlybucket_m1";
import HourlyBucketM2 from "../components/hourlybucket_m2";
import EnergyManagement from "../components/energymanagement";
import BasicGauges from "../components/chartssize";
import AirQualityManagement from "../components/airqualitymanagement";
import DieselGenset from "../components/dieselgenset";
import STPManagement from "../components/stpmanagement";
import EnlargedChartPage from "../components/fullviewchart";
import WaterManagement from "../components/watermanagement";
import Welcome from "../components/welcome";
import DailyReportM1 from "../components/dailyreportm1";
import DailyReportM2 from "../components/dailyreportm2";
import WeeklyReportM1 from "../components/weeklyreportm1";
import WeeklyReportM2 from "../components/weeklyreportm2";
import MonthlyReportM1 from "../components/monthlyreportm1";
import MonthlyReportM2 from "../components/monthlyreportm2";
import QuaterlyReportM1 from "../components/quaterlyreportm1";
import QuaterlyReportM2 from "../components/quaterlyreportm2";
import YearlyReportM1 from "../components/yearlyreportm1";
import YearlyReportM2 from "../components/yearlyreportm2";
import UnknownLoss from "../components/unknownloss";
import COntrolRoom from "../components/controlroom";
import WholeDayReportM1 from "../components/wholedayreportm1";
import WholeDayReportM2 from "../components/wholedayreportm2";
import ChartComponent from "../components/charts";
import Dashboard from "../components/dashboard";
import Publisher from "../components/publishers";
import Tbm from "../components/tbm";
import Thresholds from "../components/thresholds";
import ADMReports from "../components/Reports";
import SmartDashboard from "../components/SmartDashboard";
import EMSDashboard from "../components/EMS/EMSDashboard";
import RawData from "../components/EMS/RawData";
import CBMDetail from "../components/Scoul/cbmDetails";
import IconicDashboard from "../components/Scoul/CockPitView";
import VibrationGraphs from "../components/Scoul/CBMGraphs";
import PowerGraphs from "../components/Scoul/EnergyGraphs";
import ADMRawData from "../components/ADMRawData";
import SoftwareReportTable from "../components/PTPL/DemoReports";
import PTPLPartMaster from "../components/PTPL/PartMaster";
import PTPLSettings from "../components/PTPL/Settings";
import PTPLMonitoring from "../components/PTPL/Monitoring";
import PTPLTestingReport from "../components/PTPL/TestingReport";
import PTPLCustomerMaster from "../components/PTPL/CustomerMaster";
import PTPLOperatorMaster from "../components/PTPL/OperatorMaster";
import PTPLMonitoringWrapper from "../components/PTPL/PTPLMonitoringWrapper";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  return children;
};

export default function MyRoutes() {
  return (
    <Routes>
      <Route path="" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/cbm"
        element={
          <ProtectedRoute>
            <CBM />
          </ProtectedRoute>
        }
      />
        <Route path="/cbm" element={<ProtectedRoute><CBM /></ProtectedRoute>} />
      <Route path="/operations" element={<ProtectedRoute><Operations /></ProtectedRoute>} />
      <Route path="/pathredi_ope" element={<ProtectedRoute><PathrediOpe /></ProtectedRoute>} />
      <Route path="/uat/uat_rawdata" element={<ProtectedRoute><UATRawData /></ProtectedRoute>} />
      <Route path="/uat/uat_reports" element={<ProtectedRoute><UATReport /></ProtectedRoute>} />
      <Route path="/uat/uat_testing" element={<ProtectedRoute><UATTesting /></ProtectedRoute>} />
      <Route path="/rawdata" element={<ProtectedRoute><RawData /></ProtectedRoute>} />
      <Route path="/ghaziabad_ope" element={<ProtectedRoute><GhaziabadOpe /></ProtectedRoute>} />
      <Route path="/scoul/cbmdetail" element={<ProtectedRoute><CBMDetail /></ProtectedRoute>} />
      <Route path="/facilitymanagement" element={<ProtectedRoute><FacilityManagement /></ProtectedRoute>} />
      <Route path="/plant_architecture/plant_master" element={<ProtectedRoute><PlantMaster /></ProtectedRoute>} />
      <Route path="/plant_architecture/linemaster" element={<ProtectedRoute><LineMaster /></ProtectedRoute>} />
      <Route path="/plant_architecture/devicemaster" element={<ProtectedRoute><DeviceMaster /></ProtectedRoute>} />
      <Route path="/publishers" element={<ProtectedRoute><Publisher /></ProtectedRoute>} />
      <Route path="/tbm" element={<ProtectedRoute><Tbm /></ProtectedRoute>} />
      <Route path="/admreports" element={<ProtectedRoute><ADMReports /></ProtectedRoute>} />
      <Route path="/smart_dashboard" element={<ProtectedRoute><SmartDashboard /></ProtectedRoute>} />
      <Route path="/scoul/cockpitview" element={<ProtectedRoute><IconicDashboard /></ProtectedRoute>} />
      <Route path="/scoul/powergraphs" element={<ProtectedRoute><PowerGraphs /></ProtectedRoute>} />
      <Route path="/scoul/cbmgraphs" element={<ProtectedRoute><VibrationGraphs /></ProtectedRoute>} />
      <Route path="plant_architecture/thresholds" element={<ProtectedRoute><Thresholds /></ProtectedRoute>} />
      <Route path="/plant_architecture/part_master" element={<ProtectedRoute><PartMaster /></ProtectedRoute>} />
      <Route path="/production_plan/machine_downtime" element={<ProtectedRoute><MachineDownTime /></ProtectedRoute>} />
      <Route path="/production_plan/quality_rejection" element={<ProtectedRoute><QualityRejection /></ProtectedRoute>} />
      <Route path="/production_plan/unknown_loss" element={<ProtectedRoute><UnknownLoss /></ProtectedRoute>} />
      <Route path="/device_status" element={<ProtectedRoute><COntrolRoom /></ProtectedRoute>} />
      <Route path="/production_plan/shift_master" element={<ProtectedRoute><ShiftMaster /></ProtectedRoute>} />
      <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
      <Route path="/method2/machine_input" element={<ProtectedRoute><MachineInput /></ProtectedRoute>} />
      <Route path="/method2/ghaziabad_ope_2" element={<ProtectedRoute><GhaziabadOpe2 /></ProtectedRoute>} />
      <Route path="/method2/pathredi_ope_2" element={<ProtectedRoute><PathrediOpe2 /></ProtectedRoute>} />
      <Route path="/method1/pathredi_ope_1" element={<ProtectedRoute><PathrediOpe /></ProtectedRoute>} />
      <Route path="/method1/ghaziabad_ope_1" element={<ProtectedRoute><GhaziabadOpe /></ProtectedRoute>} />
      <Route path="/plant_architecture/frdc_master" element={<ProtectedRoute><ChangeOverMaster /></ProtectedRoute>} />
      <Route path="/facilitysustainability" element={<ProtectedRoute><FacilitySustainability /></ProtectedRoute>} />
      <Route path="/energymanagement" element={<ProtectedRoute><EnergyManagement /></ProtectedRoute>} />
      <Route path="/airquality" element={<ProtectedRoute><AirQualityManagement /></ProtectedRoute>} />
      <Route path="/dieselgenset" element={<ProtectedRoute><DieselGenset /></ProtectedRoute>} />
      <Route path="/common/emsdashboard" element={<ProtectedRoute><EMSDashboard /></ProtectedRoute>} />
      <Route path="/common/rawdata" element={<ProtectedRoute><RawData /></ProtectedRoute>} />
      <Route path="/charts" element={<ProtectedRoute><ChartComponent /></ProtectedRoute>} />
      <Route path="/temp" element={<ProtectedRoute><Temp /></ProtectedRoute>} />
      <Route path="/basic" element={<ProtectedRoute><BasicGauges /></ProtectedRoute>} />
      <Route path="/stpmanagement" element={<ProtectedRoute><STPManagement /></ProtectedRoute>} />
      <Route path="/fullviewchart" element={<ProtectedRoute><EnlargedChartPage /></ProtectedRoute>} />
      <Route path="/watermanagement" element={<ProtectedRoute><WaterManagement /></ProtectedRoute>} />
      <Route path="/welcome" element={<ProtectedRoute><Welcome /></ProtectedRoute>} />
      <Route path="/reportm1/daily" element={<ProtectedRoute><DailyReportM1 /></ProtectedRoute>} />
      <Route path="/reportm1/hour_bucket_m1" element={<ProtectedRoute><HourlyBucketM1 /></ProtectedRoute>} />
      <Route path="/reportm2/hour_bucket_m2" element={<ProtectedRoute><HourlyBucketM2 /></ProtectedRoute>} />
      <Route path="/reportm2/daily" element={<ProtectedRoute><DailyReportM2 /></ProtectedRoute>} />
      <Route path="/reportm1/wholedayreportm1" element={<ProtectedRoute><WholeDayReportM1 /></ProtectedRoute>} />
      <Route path="/reportm2/wholedayreportm2" element={<ProtectedRoute><WholeDayReportM2 /></ProtectedRoute>} />
      <Route path="/reportm1/weekly" element={<ProtectedRoute><WeeklyReportM1 /></ProtectedRoute>} />
      <Route path="/reportm2/weekly" element={<ProtectedRoute><WeeklyReportM2 /></ProtectedRoute>} />
      <Route path="/reportm1/monthly" element={<ProtectedRoute><MonthlyReportM1 /></ProtectedRoute>} />
      <Route path="/reportm2/monthly" element={<ProtectedRoute><MonthlyReportM2 /></ProtectedRoute>} />
      <Route path="/reportm1/quaterly" element={<ProtectedRoute><QuaterlyReportM1 /></ProtectedRoute>} />
      <Route path="/reportm2/quaterly" element={<ProtectedRoute><QuaterlyReportM2 /></ProtectedRoute>} />
      <Route path="/reportm1/yearly" element={<ProtectedRoute><YearlyReportM1 /></ProtectedRoute>} />
      <Route path="/reportm2/yearly" element={<ProtectedRoute><YearlyReportM2 /></ProtectedRoute>} />
      <Route path="/adm/rawdata" element={<ProtectedRoute><ADMRawData /></ProtectedRoute>} />
      <Route path="/ptpl/ptplreports" element={<ProtectedRoute><SoftwareReportTable /></ProtectedRoute>} />
      <Route path="/ptpl/partmaster" element={<ProtectedRoute><PTPLPartMaster /></ProtectedRoute>} />
      <Route path="/ptpl/ptplsettings" element={<ProtectedRoute><PTPLSettings /></ProtectedRoute>} />
      <Route path="/ptpl/ptplmonitoring" element={<ProtectedRoute><PTPLMonitoring /></ProtectedRoute>} />
      <Route path="/ptpl/ptpltesting" element={<ProtectedRoute><PTPLTestingReport /></ProtectedRoute>} />
      <Route path="/ptpl/customermaster" element={<ProtectedRoute><PTPLCustomerMaster /></ProtectedRoute>} />
      <Route path="/ptpl/operatormaster" element={<ProtectedRoute><PTPLOperatorMaster /></ProtectedRoute>} />
      <Route path="/ptpl/addparts" element={<ProtectedRoute><PTPLPartMaster /></ProtectedRoute>} />
      <Route path="/plant_architecture/machine_master" element={<ProtectedRoute><MachineMaster /></ProtectedRoute>} />
    </Routes>
  );
}